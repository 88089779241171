/** AdjacencyHazardInfo */
export interface AdjacencyHazardInfo {
  /**
   * Annual Probability Of Incident
   * Annual probability of incident per length of track/road
   * @min 0
   * @max 1
   */
  annual_probability_of_incident: number;
  /**
   * Level
   * Level of the adjacency hazard
   */
  level: number;
  /**
   * Description
   * Description of the consequences associated to the adjacency hazard level
   */
  description: string;
  /** Degree of resulting downtime. */
  downtime: DowntimeConsequence;
  /**
   * Metric
   *  Description of the metrics associated to the adjacency hazard
   */
  metric?: string | null;
  /**
   * Radius
   * Radius of the circle that describes the area influenced by the adjacency hazard.
   * @min 0
   */
  radius: number;
}

/** AdjacencyInfo */
export interface AdjacencyInfo {
  /**
   * Site Id
   * Prospective site id.
   */
  site_id: string;
  /**
   * Adjacent Site Id
   * Adjacent site id
   */
  adjacent_site_id: string;
  /** Typology of the site. Possible values: ['AIRPORT', 'ROAD', 'RAIL'] */
  adjacency_type: AdjacencyTypeEnum;
  /**
   * Risk Params
   * Typology of the site. Possible values: ['AIRPORT', 'ROAD', 'RAIL']
   */
  risk_params:
    | AirportAdjacencyRiskRequestApi
    | RailAdjacencyRiskRequestApi
    | RoadAdjacencyRiskRequestApi;
}

/** AdjacencyRiskBatchRequestApi */
export interface AdjacencyRiskBatchRequestApi {
  /**
   * Site Id
   * Prospective site id.
   */
  site_id: string;
  /**
   * Adjacent Site Id
   * Adjacent site id
   */
  adjacent_site_id: string;
  /** Typology of the site. Possible values: ['AIRPORT', 'ROAD', 'RAIL'] */
  adjacency_type: AdjacencyTypeEnum;
  /**
   * Risk Params
   * Typology of the site. Possible values: ['AIRPORT', 'ROAD', 'RAIL']
   */
  risk_params:
    | AirportAdjacencyRiskRequestApi
    | RailAdjacencyRiskRequestApi
    | RoadAdjacencyRiskRequestApi;
}

/** AdjacencyRiskBatchResponseApi */
export interface AdjacencyRiskBatchResponseApi {
  /**
   * Results
   * List of adjacency risks successfully calculated
   */
  results?: AdjacencyRiskResponseApi[] | null;
  /**
   * Failed Adjacency Risks
   * List of adjacency risks for which the calculation failed.
   */
  failed_adjacency_risks: AdjacencyRiskCalculationBatchFailedInfo[];
}

/** AdjacencyRiskCalculationBatchFailedInfo */
export interface AdjacencyRiskCalculationBatchFailedInfo {
  /**
   * Site Id
   * Prospective site id.
   */
  site_id: string;
  /**
   * Adjacent Site Id
   * Adjacent site id
   */
  adjacent_site_id: string;
  /** Typology of the site. Possible values: ['AIRPORT', 'ROAD', 'RAIL'] */
  adjacency_type: AdjacencyTypeEnum;
  /**
   * Risk Params
   * Typology of the site. Possible values: ['AIRPORT', 'ROAD', 'RAIL']
   */
  risk_params:
    | AirportAdjacencyRiskRequestApi
    | RailAdjacencyRiskRequestApi
    | RoadAdjacencyRiskRequestApi;
  /**
   * Error Log
   * Reason why the risk calculation failed.
   */
  error_log: string;
}

/** AdjacencyRiskDataInfo */
export interface AdjacencyRiskDataInfo {
  /** Risk appetite. */
  risk_appetite: RiskAppetite;
  /** Highest Risk rating. Possible values: ['Catastrophic', 'Very High', 'High-Very High', 'High', 'Medium-High', 'Medium', 'Low-Medium', 'Low', 'Very Low', 'Negligible', 'Not Assessed', 'Plausible'] */
  risk_rating: RiskRatingEnum;
  /**
   * Dominant Levels
   * Levels of the adjacency hazard with highest risk rating.
   */
  dominant_levels?: number[] | null;
}

/** AdjacencyRiskResponseApi */
export interface AdjacencyRiskResponseApi {
  /**
   * Site Id
   * Prospective site id.
   */
  site_id: string;
  /**
   * Adjacent Site Id
   * Adjacent site id
   */
  adjacent_site_id: string;
  /** Typology of the site. Possible values: ['AIRPORT', 'ROAD', 'RAIL'] */
  adjacency_type: AdjacencyTypeEnum;
  /** Risk Params */
  risk_params:
    | AirportAdjacencyRiskRequestApi
    | RailAdjacencyRiskRequestApi
    | RoadAdjacencyRiskRequestApi;
  /**
   * Site Name
   * Prospective site name.
   */
  site_name?: string | null;
  /**
   * Adjacent Site Name
   * Adjacent site name.
   */
  adjacent_site_name?: string | null;
  /**
   * Adjacent Site Params
   * Adjacent site parameters.
   */
  adjacent_site_params?: object | null;
  /** Adjacency sb-hazard type. */
  subhazard_type: SubHazardType;
  /**
   * Calculation Details
   * Risk rating calculation details.
   */
  calculation_details?: object | null;
  /**
   * Risk Rating Data
   * Adjacency risk rating for the dominant levels for different risk appetites.
   */
  risk_rating_data: AdjacencyRiskDataInfo[];
  /** Error Log */
  error_log: string | null;
}

/** AdjacencyTypeEnum */
export enum AdjacencyTypeEnum {
  AIRPORT = "AIRPORT",
  ROAD = "ROAD",
  RAIL = "RAIL",
}

/** AirportAdjacencyRiskRequestApi */
export interface AirportAdjacencyRiskRequestApi {
  /**
   * Distance X
   * Distance from the end of the runway measured along the centerline.
   */
  distance_x: number;
  /**
   * Distance Y
   * Perpendicular distance to the site from the extended centerline.
   */
  distance_y: number;
}

/** AllHazardsEnum */
export type AllHazardsEnum = "all_hazards";

/** AnchorQualityEnum */
export enum AnchorQualityEnum {
  Good = "Good",
  Fair = "Fair",
  Poor = "Poor",
  VeryPoor = "Very Poor",
}

/** ArchetypeAttributes */
export interface ArchetypeAttributes {
  /**
   * Attrs
   * Dictionary of valid key/values pairs of properties in archetype_data for filtering the search of archetypes
   */
  attrs: object;
}

/** ArchetypeLossCurve */
export interface ArchetypeLossCurveInput {
  /**
   * Curve Type
   * @default "ARCHETYPE_LOSS"
   */
  curve_type?: "ARCHETYPE_LOSS";
  /**
   * Units
   * The units of the curve.
   * @maxItems 2
   * @minItems 2
   */
  units: any[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /**
   * Dimension
   * Dimension of the archetype loss curve.
   * @maxItems 2
   * @minItems 2
   */
  dimension: any[];
  /**
   * Percentile
   * Percentile estimate that the loss curve represents
   * @min 0
   * @max 100
   * @default 50
   */
  percentile?: number;
}

/** ArchetypeLossCurve */
export interface ArchetypeLossCurveOutput {
  /**
   * Curve Type
   * @default "ARCHETYPE_LOSS"
   */
  curve_type?: "ARCHETYPE_LOSS";
  /**
   * Units
   * The units of the curve.
   */
  units: string[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /**
   * Dimension
   * Dimension of the archetype loss curve.
   * @maxItems 2
   * @minItems 2
   */
  dimension: any[];
  /**
   * Percentile
   * Percentile estimate that the loss curve represents
   * @min 0
   * @max 100
   * @default 50
   */
  percentile?: number;
}

/** ArchetypeResponse */
export interface ArchetypeResponse {
  /**
   * Id
   * Main key to identify each Archetype
   */
  id: string;
  /**
   * Major Version
   * Major version of the archetype
   * @min 1
   */
  major_version: number;
  /**
   * Minor Version
   * Minor version of the archetype
   * @min 1
   */
  minor_version: number;
  /**
   * Defines the scope of the archetype. It can be 'GLOBAL' or 'ORGANIZATION'.
   * @default "GLOBAL"
   */
  scope?: Scope;
  /**
   * Unitary Replacement Cost
   * Unit building replacement cost category [$/sq ft].
   */
  unitary_replacement_cost?: number | null;
  /**
   * Footprint
   * The assumed Area of ground floor for the archetype [sq ft]
   */
  footprint?: number | null;
  /**
   * N Floors
   * Total assumed number of stories, including above and below ground, for the archetype
   */
  n_floors?: number | null;
  /**
   * Name
   * Name of the archetype.
   */
  name?: string | null;
  /**
   * Risk Classes
   * Risk classes for which the archetypes can be used.
   */
  risk_classes?: RiskClassEnum[];
  /**
   * Loss Curves
   * Loss curves for the archetype.
   */
  loss_curves?: ArchetypeLossCurveOutput[];
  /**
   * Description
   * Description of the archetype
   */
  description?: string | null;
  /** Type of Hazard. Allowed values: (<HazardType.FLOOD: 'FLOOD'>, <HazardType.WIND: 'WIND'>, <HazardType.WILDFIRE: 'WILDFIRE'>) */
  hazard_type: HazardType;
  /** Archetype Data */
  archetype_data: object;
  /**
   * Created At
   * When the archetype was created
   * @format date-time
   */
  created_at?: string;
  /**
   * Created By
   * Who created the archetype
   */
  created_by: string;
  /**
   * Updated At
   * When the archetype was updated last time
   */
  updated_at?: string | null;
  /**
   * Updated By
   * Who updated the archetype last time
   */
  updated_by?: string | null;
  /**
   * Archived
   * If the archetype is archived or not
   * @default false
   */
  archived?: boolean;
}

/** AssessmentComponentResponse */
export interface AssessmentComponentResponse {
  /**
   * Component
   * Component name
   */
  component: string;
  /** Region associated to the component */
  region: ComponentRegion;
  /** Risk engine associated to the component */
  risk_engine: HazardType;
  /**
   * Subgroup
   * Component subgroup
   */
  subgroup?: string | null;
  /**
   * Compdesc
   * Component description
   */
  compdesc?: string | null;
  /**
   * Floor
   * The floor the component is on
   */
  floor: number;
  /**
   * Nistr
   * Identification tag for the component
   */
  NISTR: string;
  /**
   * Qty
   * X and Y Quantity
   * @maxItems 2
   * @minItems 2
   */
  qty: number[];
  /**
   * Elev
   * Elevation values for this component on the given floor
   * @maxItems 2
   * @minItems 2
   */
  elev: number[];
  /**
   * Id
   * Main key to identify each component, cannot be repeated and should not be modified
   */
  id: string;
  /**
   * Assessment Id
   * Assessment ID to which the component is created for
   */
  assessment_id: string;
}

/** AssessmentOutputResponse */
export interface AssessmentOutputResponse {
  /** The type of output metric (percentile or aggregate) */
  type: OutputMetricType;
  /**
   * Name
   * A subgrouping of the output metric
   * @default ""
   */
  name?: string | number | null;
  /**
   * Value
   * The value of the output
   */
  value:
    | number
    | string
    | any[][]
    | (
        | ({
            curve_type: "ARCHETYPE_LOSS";
          } & ArchetypeLossCurveOutput)
        | ({
            curve_type: "BASE";
          } & BaseCurveOutput)
        | ({
            curve_type: "HAZARD";
          } & HazardCurveOutput)
        | ({
            curve_type: "LOSS";
          } & LossCurveOutput)
        | ({
            curve_type: "RISK_RATING";
          } & RiskRatingCurveOutput)
      );
  /** The type of consequence that the output represents */
  data_key?: ConsequenceType | null;
  /**
   * Return Period
   * The return period of the output
   * @default 0
   */
  return_period?: number | null;
  /**
   * Percentile
   * The percentile associated to the output
   * @default 0
   */
  percentile?: number | null;
  /**
   * Asset Id
   * Reference to asset/asset group
   */
  asset_id: string;
  /** Type of intensity measure that the output records relate to */
  intensity_measure?: IntensityMeasureType | null;
  /** Unit of the record */
  unit?: UnitType | null;
  /**
   * Assessment Id
   * Main key to identify Quantitative Assessment
   */
  assessment_id: string;
  /** Type of risk */
  hazard_type: HazardType;
  /**
   * Output Id
   * The unique identifier of the output record
   */
  output_id: string;
  /**
   * Created At
   * When the assessment output was created
   * @format date-time
   */
  created_at: string;
  /**
   * Created By
   * Who created the assessment output
   */
  created_by?: string | null;
  /**
   * Updated At
   * When the assessment output was updated last time
   */
  updated_at?: string | null;
  /**
   * Updated By
   * Who updated the assessment output last time
   */
  updated_by?: string | null;
}

/** AssessmentRequest */
export interface AssessmentRequest {
  /**
   * Executive Summary
   * Executive Summary
   * @default ""
   */
  executive_summary?: string | null;
  /**
   * Title
   * Title of Quantitative Assessment
   */
  title?: string | null;
  /**
   * Inputs
   * Input parameters
   */
  inputs?: BaseInputParameter[];
  /**
   * Tags
   * List of tags to label the assessment. These tags can be used to filter the assessments
   */
  tags?: string[];
  /** The climate change scenario. Possible values : RCP_1P9, RCP_2P6, RCP_3P4, RCP_4P5, RCP_6P0, RCP_7P0, RCP_8P5, SSP1_1P9, SSP1_2P6, SSP2_4P5, SSP3_7P0, SSP5_8P5 */
  climate_scenario?: ClimateScenario | null;
  /**
   * Time Horizon
   * Time horizon associated to a RCP scenario. This field is mandatory for FUTURE assessments type. The year should lie in the future, or be 0 in case of CURRENT assessment.
   * @min 0
   * @default 0
   */
  time_horizon?: number;
  /**
   * Ref Id
   * Reference to asset/asset group
   */
  ref_id: string;
  /** The type of object the assessment is linked to. */
  ref_type: ReferenceTypeEnum;
  /** Type of sub-risk */
  subhazard_type: SubHazardType;
  /** Type of risk. This field is set automatically based on the subhazard_type field.  */
  hazard_type: HazardType;
  /**
   * Engine Id
   * Risk engine ID
   * @default ""
   */
  engine_id?: string | null;
  /** Status of Quantitative Assessment */
  status: StatusEnum;
  /** The risk class for the assessment */
  risk_class: RiskClassEnum;
  /**
   * Assessment type. Possible values: CREATED, IN_PROGRESS, RUNNING, PUBLISHED, FAILED, COMPLETED, QA
   * @default "CURRENT"
   */
  assessment_type?: AssessmentTypeEnum | null;
}

/** AssessmentResponse */
export interface AssessmentResponse {
  /**
   * Executive Summary
   * Executive Summary
   * @default ""
   */
  executive_summary?: string | null;
  /**
   * Title
   * Title of Quantitative Assessment
   */
  title?: string | null;
  /**
   * Inputs
   * Input parameters
   */
  inputs?: BaseInputParameter[];
  /**
   * Tags
   * List of tags to label the assessment. These tags can be used to filter the assessments
   */
  tags?: string[];
  /** The climate change scenario. Possible values : RCP_1P9, RCP_2P6, RCP_3P4, RCP_4P5, RCP_6P0, RCP_7P0, RCP_8P5, SSP1_1P9, SSP1_2P6, SSP2_4P5, SSP3_7P0, SSP5_8P5 */
  climate_scenario?: ClimateScenario | null;
  /**
   * Time Horizon
   * Time horizon associated to a RCP scenario. This field is mandatory for FUTURE assessments type. The year should lie in the future, or be 0 in case of CURRENT assessment.
   * @min 0
   * @default 0
   */
  time_horizon?: number;
  /**
   * Ref Id
   * Reference to asset/asset group
   */
  ref_id: string;
  /** The type of object the assessment is linked to. */
  ref_type: ReferenceTypeEnum;
  /** Type of sub-risk */
  subhazard_type: SubHazardType;
  /** Type of risk. This field is set automatically based on the subhazard_type field.  */
  hazard_type: HazardType;
  /**
   * Engine Id
   * Risk engine ID
   * @default ""
   */
  engine_id?: string | null;
  /** Status of Quantitative Assessment */
  status: StatusEnum;
  /** The risk class for the assessment */
  risk_class: RiskClassEnum;
  /**
   * Assessment type. Possible values: CREATED, IN_PROGRESS, RUNNING, PUBLISHED, FAILED, COMPLETED, QA
   * @default "CURRENT"
   */
  assessment_type?: AssessmentTypeEnum | null;
  /**
   * Id
   * Main key to identify Quantitative Assessment
   */
  id: string;
  /**
   * Created Date
   * When the assessment was created
   */
  created_date?: string | null;
  /**
   * Updated At
   * When the assessment info was updated last time
   */
  updated_at?: string | null;
  /**
   * Updated By
   * Who updated the assessment info last time
   */
  updated_by?: string | null;
  /**
   * Status Updated At
   * When the assessment status was updated last time
   */
  status_updated_at?: string | null;
  /**
   * Status Updated By
   * Who updated the assessment last time
   */
  status_updated_by?: string | null;
  /**
   * Outputs
   * List of the outputs for the assessment.
   */
  outputs?: (AssessmentOutputResponse | DataRecordModelOutput)[];
  /**
   * Hazard Data
   * List of the hazards of the assessment.
   */
  hazard_data?: HazardResponse[];
}

/** AssessmentTypeEnum */
export enum AssessmentTypeEnum {
  CURRENT = "CURRENT",
  FUTURE = "FUTURE",
}

/** AssessmentUpdateEnum */
export enum AssessmentUpdateEnum {
  Value6Months = "6 months",
  Value12Months = "12 months",
  Value24Months = "24 months",
  Value36Months = "36 months",
  Value48Months = "48 months",
}

/** AssessmentUpdateRequest */
export interface AssessmentUpdateRequest {
  /**
   * Executive Summary
   * Executive Summary
   * @default ""
   */
  executive_summary?: string | null;
  /**
   * Title
   * Title of Quantitative Assessment
   */
  title?: string | null;
  /**
   * Inputs
   * Input parameters
   */
  inputs?: BaseInputParameter[];
  /**
   * Tags
   * List of tags to label the assessment. These tags can be used to filter the assessments
   */
  tags?: string[];
  /** The climate change scenario. Possible values : RCP_1P9, RCP_2P6, RCP_3P4, RCP_4P5, RCP_6P0, RCP_7P0, RCP_8P5, SSP1_1P9, SSP1_2P6, SSP2_4P5, SSP3_7P0, SSP5_8P5 */
  climate_scenario?: ClimateScenario | null;
  /**
   * Time Horizon
   * Time horizon associated to a RCP scenario. This field is mandatory for FUTURE assessments type. The year should lie in the future, or be 0 in case of CURRENT assessment.
   * @min 0
   * @default 0
   */
  time_horizon?: number;
}

/** AssetsOutputRequest */
export interface AssetsOutputRequest {
  /** The type of output metric (percentile or aggregate) */
  type: OutputMetricType;
  /**
   * Name
   * A subgrouping of the output metric
   * @default ""
   */
  name?: string | number | null;
  /**
   * Value
   * The value of the output
   */
  value?:
    | number
    | string
    | any[][]
    | (
        | ({
            curve_type: "ARCHETYPE_LOSS";
          } & ArchetypeLossCurveInput)
        | ({
            curve_type: "BASE";
          } & BaseCurveInput)
        | ({
            curve_type: "HAZARD";
          } & HazardCurveInput)
        | ({
            curve_type: "LOSS";
          } & LossCurveInput)
        | ({
            curve_type: "RISK_RATING";
          } & RiskRatingCurveInput)
      )
    | null;
  /** The type of consequence that the output represents */
  data_key?: ConsequenceType | null;
  /**
   * Return Period
   * The return period of the output
   */
  return_period?: number | null;
  /**
   * Percentile
   * The percentile associated to the output
   * @default 0
   */
  percentile?: number | null;
  /**
   * Asset Id
   * The asset ID that the output records relate to. This is used to differentiate output in group level assessments
   */
  asset_id?: string | null;
  /** Type of intensity measure that the output records relate to */
  intensity_measure?: IntensityMeasureType | null;
  /** Unit of the record */
  unit?: UnitType | null;
  /**
   * Asset Ids
   * A list of asset ids to evaluate across
   */
  asset_ids: string[];
  /** Type of hazard */
  hazard_type: HazardType;
}

/** AssetsOutputResponse */
export interface AssetsOutputResponse {
  /** The type of output metric (percentile or aggregate) */
  type?: OutputMetricType | null;
  /**
   * Name
   * A subgrouping of the output metric
   */
  name?: string | number | null;
  /**
   * Value
   * The value of the output
   */
  value:
    | number
    | string
    | any[][]
    | (
        | ({
            curve_type: "ARCHETYPE_LOSS";
          } & ArchetypeLossCurveOutput)
        | ({
            curve_type: "BASE";
          } & BaseCurveOutput)
        | ({
            curve_type: "HAZARD";
          } & HazardCurveOutput)
        | ({
            curve_type: "LOSS";
          } & LossCurveOutput)
        | ({
            curve_type: "RISK_RATING";
          } & RiskRatingCurveOutput)
      );
  /** The type of consequence that the output represents */
  data_key?: ConsequenceType | null;
  /**
   * Return Period
   * The return period of the output
   * @default 0
   */
  return_period?: number | null;
  /**
   * Percentile
   * The percentile associated to the output
   * @default 0
   */
  percentile?: number | null;
  /**
   * Asset Id
   * The asset ID that the output records relate to. This is used to differentiate output in group level assessments
   */
  asset_id?: string | null;
  /** Type of intensity measure that the output records relate to */
  intensity_measure?: IntensityMeasureType | null;
  /** Unit of the record */
  unit?: UnitType | null;
}

/**
 * BaseCurve
 * Represents a curve consisting of a list of DataPair objects.
 *
 * A DataPair is a tuple of two values representing an (x, y) pair of data.
 * The BaseCurve class provides methods for manipulating the y values of the DataPair objects.
 */
export interface BaseCurveInput {
  /**
   * Curve Type
   * @default "BASE"
   */
  curve_type?: "BASE";
  /**
   * Units
   * The units of the curve.
   * @maxItems 2
   * @minItems 2
   */
  units: any[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /** Dimension */
  dimension?: any[] | null;
}

/**
 * BaseCurve
 * Represents a curve consisting of a list of DataPair objects.
 *
 * A DataPair is a tuple of two values representing an (x, y) pair of data.
 * The BaseCurve class provides methods for manipulating the y values of the DataPair objects.
 */
export interface BaseCurveOutput {
  /**
   * Curve Type
   * @default "BASE"
   */
  curve_type?: "BASE";
  /**
   * Units
   * The units of the curve.
   */
  units: string[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /** Dimension */
  dimension?: any[] | null;
}

/** BaseInputParameter */
export interface BaseInputParameter {
  /**
   * Id
   * Unique identifier for the field
   */
  id: string;
  /** Unit of the parameter */
  unit?: UnitType | null;
  /**
   * Value
   * Value of the parameter
   */
  value?: null;
  /**
   * Default Value
   * Default value of the parameter
   */
  default_value?: null;
}

/** Body_create_archetypes_in_batch_archetypes_batch_post */
export interface BodyCreateArchetypesInBatchArchetypesBatchPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_create_hazard_from_resource_hazards_resource_post */
export interface BodyCreateHazardFromResourceHazardsResourcePost {
  /** Assessment Id */
  assessment_id: string;
  subhazard_type: SubHazardType;
  /** Return Period */
  return_period: number;
  intensity_measure: IntensityMeasureType;
  /**
   * Units
   * @maxItems 2
   * @minItems 2
   */
  units: any[];
  /** Time Horizon */
  time_horizon: number;
  /** Hazard Source */
  hazard_source?: string | null;
  climate_scenario?: ClimateScenario | null;
  /** Hazard Distribution */
  hazard_distribution: "Custom" | "EDP";
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_create_parameters_in_batch_parameters_batch_post */
export interface BodyCreateParametersInBatchParametersBatchPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_update_map_layer_file_map_layer__layer_id__file_patch */
export interface BodyUpdateMapLayerFileMapLayerLayerIdFilePatch {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_file_resources__post */
export interface BodyUploadFileResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_map_layer_map_layer_post */
export interface BodyUploadMapLayerMapLayerPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** ClimateScenario */
export enum ClimateScenario {
  RCP1P9 = "RCP_1P9",
  RCP2P6 = "RCP_2P6",
  RCP3P4 = "RCP_3P4",
  RCP4P5 = "RCP_4P5",
  RCP6P0 = "RCP_6P0",
  RCP7P0 = "RCP_7P0",
  RCP8P5 = "RCP_8P5",
  SSP11P9 = "SSP1_1P9",
  SSP12P6 = "SSP1_2P6",
  SSP24P5 = "SSP2_4P5",
  SSP37P0 = "SSP3_7P0",
  SSP58P5 = "SSP5_8P5",
}

/** CompleteEngineRun */
export interface CompleteEngineRun {
  /** Hazard Id */
  hazard_id: string;
  /** Message */
  message: string;
}

/** ComponentRegion */
export enum ComponentRegion {
  USA = "USA",
  EUROPE = "EUROPE",
  UKIMEA = "UKIMEA",
  AUSTRALASIA = "AUSTRALASIA",
}

/** ComponentRiskClassModel */
export interface ComponentRiskClassModel {
  /**
   * Nistr
   * NISTR of the component
   */
  NISTR: string;
  /**
   * Component Name
   * Name of the component associated to NISTR
   */
  component_name?: string | null;
  /**
   * Return Period
   * Return period in years
   */
  return_period: number;
  /**
   * Repair Class
   * Repair class
   */
  repair_class: number;
  /**
   * Repair Class Name
   * Repair class name
   */
  repair_class_name: string;
  /**
   * Value
   * Percentage of the components (w.t.r. realization) that belong to a Repair Class
   */
  value: number;
}

/** Consequence */
export interface Consequence {
  /** The Consequence type */
  consequence: ConsequenceType;
  /** The Consequence group */
  group: ConsequenceGroup;
  /** The unit of the consequence */
  unit: UnitType;
  /**
   * Unit Label
   * The label of the unit
   */
  unit_label?: string | null;
  /**
   * Tags
   * List of tags associated with the consequence.
   */
  tags?: string[];
}

/** ConsequenceGroup */
export enum ConsequenceGroup {
  ASSET = "ASSET",
  SITE_ACCESS = "SITE_ACCESS",
  UTILITY = "UTILITY",
}

/** ConsequenceRatingsDict */
export interface ConsequenceRatingsDictInput {
  /**
   * Negligible
   * Flag that further analysis is needed
   */
  negligible?: boolean | null;
  /**
   * Further Analysis Needed
   * Flag that it is necessary to perform further analysis
   */
  further_analysis_needed?: boolean | null;
  /**
   * Further Analysis Explanation
   * Motivations why it is necessary to perform further analysis
   */
  further_analysis_explanation?: string | null;
  /** Frequency of the assessment update */
  assessment_update?: AssessmentUpdateEnum | null;
  /**
   * Summary
   * Summary describing how the giving risk ratings for a particular hazard were established.
   */
  summary?: string | null;
  /**
   * Methodology
   * The methodology used to get to the given risk ratings for a particular hazard.
   */
  methodology?: string | null;
  /**
   * Hazard Source
   * Any of the data sources used to get to the given risk rating for a particular hazard.
   */
  hazard_source?: string | null;
  /** Hazard rating */
  hazard_rating?: RatingsDict | null;
  /** Health and wellness Ratings */
  health_and_wellness?: RatingsDict | null;
  /** Severe Weather Warning Ratings */
  severe_weather_warning?: RatingsDict | null;
  /** Economic Loss Ratings */
  economic_loss?: RatingsDict | null;
  /** Inventory Loss Ratings */
  inventory_loss?: RatingsDict | null;
  /** Functional Downtime Ratings */
  downtime?: RatingsDict | null;
  /** Life Safety Ratings */
  life_safety?: RatingsDict | null;
  /** Aggregate Life Safety Ratings */
  aggregate_life_safety?: RatingsDict | null;
  /** Site Access Roads Ratings */
  siteaccess_roads?: RatingsDict | null;
  /** Site Access Bridges Ratings */
  siteaccess_bridges?: RatingsDict | null;
  /** Site Access Rail Ratings */
  siteaccess_rail?: RatingsDict | null;
  /** Utility Power Ratings */
  utility_power?: RatingsDict | null;
  /** Utility Water Ratings */
  utility_water?: RatingsDict | null;
  /** Utility Waste Ratings */
  utility_waste?: RatingsDict | null;
  /** Utility Telecom Ratings */
  utility_telecom?: RatingsDict | null;
  /** Utility Fiber Ratings */
  utility_fiber?: RatingsDict | null;
  /** Damage Ratings */
  damage?: RatingsDict | null;
  /**
   * Assessment Id
   * The assessment ID of the assessment the hazard rating is tied to.
   */
  assessment_id?: string | null;
}

/** ConsequenceRatingsDict */
export interface ConsequenceRatingsDictOutput {
  /**
   * Negligible
   * Flag that further analysis is needed
   */
  negligible?: boolean | null;
  /**
   * Further Analysis Needed
   * Flag that it is necessary to perform further analysis
   */
  further_analysis_needed?: boolean | null;
  /**
   * Further Analysis Explanation
   * Motivations why it is necessary to perform further analysis
   */
  further_analysis_explanation?: string | null;
  /** Frequency of the assessment update */
  assessment_update?: AssessmentUpdateEnum | null;
  /**
   * Summary
   * Summary describing how the giving risk ratings for a particular hazard were established.
   */
  summary?: string | null;
  /**
   * Methodology
   * The methodology used to get to the given risk ratings for a particular hazard.
   */
  methodology?: string | null;
  /**
   * Hazard Source
   * Any of the data sources used to get to the given risk rating for a particular hazard.
   */
  hazard_source?: string | null;
  /** Hazard rating */
  hazard_rating?: RatingsDict | null;
  /** Health and wellness Ratings */
  health_and_wellness?: RatingsDict | null;
  /** Severe Weather Warning Ratings */
  severe_weather_warning?: RatingsDict | null;
  /** Economic Loss Ratings */
  economic_loss?: RatingsDict | null;
  /** Inventory Loss Ratings */
  inventory_loss?: RatingsDict | null;
  /** Functional Downtime Ratings */
  downtime?: RatingsDict | null;
  /** Life Safety Ratings */
  life_safety?: RatingsDict | null;
  /** Aggregate Life Safety Ratings */
  aggregate_life_safety?: RatingsDict | null;
  /** Site Access Roads Ratings */
  siteaccess_roads?: RatingsDict | null;
  /** Site Access Bridges Ratings */
  siteaccess_bridges?: RatingsDict | null;
  /** Site Access Rail Ratings */
  siteaccess_rail?: RatingsDict | null;
  /** Utility Power Ratings */
  utility_power?: RatingsDict | null;
  /** Utility Water Ratings */
  utility_water?: RatingsDict | null;
  /** Utility Waste Ratings */
  utility_waste?: RatingsDict | null;
  /** Utility Telecom Ratings */
  utility_telecom?: RatingsDict | null;
  /** Utility Fiber Ratings */
  utility_fiber?: RatingsDict | null;
  /** Damage Ratings */
  damage?: RatingsDict | null;
  /**
   * Assessment Id
   * The assessment ID of the assessment the hazard rating is tied to.
   */
  assessment_id?: string | null;
}

/** ConsequenceType */
export enum ConsequenceType {
  FINANCIAL_LOSS = "FINANCIAL_LOSS",
  INJURIES = "INJURIES",
  FATALITIES = "FATALITIES",
  FUNCTIONAL_RECOVERY = "FUNCTIONAL_RECOVERY",
  IMMEDIATE_OCCUPANCY = "IMMEDIATE_OCCUPANCY",
  FULL_RECOVERY = "FULL_RECOVERY",
  COLLAPSE = "COLLAPSE",
  ECONOMIC_LOSS = "ECONOMIC_LOSS",
  INVENTORY_LOSS = "INVENTORY_LOSS",
  LIFE_SAFETY = "LIFE_SAFETY",
  AGGREGATE_LIFE_SAFETY = "AGGREGATE_LIFE_SAFETY",
  HEALTH_AND_WELLNESS = "HEALTH_AND_WELLNESS",
  DAMAGE = "DAMAGE",
  SEVERE_WEATHER_WARNING = "SEVERE_WEATHER_WARNING",
  HAZARD_RATING = "HAZARD_RATING",
  REPAIR_COST = "REPAIR_COST",
  DOWNTIME = "DOWNTIME",
  HOSPITALIZATION = "HOSPITALIZATION",
  MISERY = "MISERY",
  ROADS = "ROADS",
  BRIDGES = "BRIDGES",
  RAIL = "RAIL",
  POWER = "POWER",
  WATER = "WATER",
  WASTE = "WASTE",
  TELECOM = "TELECOM",
  FIBER = "FIBER",
}

/** CreateArchetypeRequest */
export interface CreateArchetypeRequest {
  /**
   * Unitary Replacement Cost
   * Unit building replacement cost category [$/sq ft].
   */
  unitary_replacement_cost?: number | null;
  /**
   * Footprint
   * The assumed Area of ground floor for the archetype [sq ft]
   */
  footprint?: number | null;
  /**
   * N Floors
   * Total assumed number of stories, including above and below ground, for the archetype
   */
  n_floors?: number | null;
  /**
   * Name
   * Name of the archetype.
   */
  name?: string | null;
  /**
   * Risk Classes
   * Risk classes for which the archetypes can be used.
   */
  risk_classes?: RiskClassEnum[];
  /**
   * Loss Curves
   * Loss curves for the archetype.
   */
  loss_curves?: ArchetypeLossCurveInput[];
  /**
   * Description
   * Description of the archetype
   */
  description?: string | null;
  /** Type of Hazard. Allowed values: (<HazardType.FLOOD: 'FLOOD'>, <HazardType.WIND: 'WIND'>, <HazardType.WILDFIRE: 'WILDFIRE'>) */
  hazard_type: HazardType;
  /** Archetype Data */
  archetype_data: object;
}

/** CreateAssessmentComponentRequest */
export interface CreateAssessmentComponentRequest {
  /**
   * Floor
   * The floor the component is on
   */
  floor: number;
  /**
   * Nistr
   * Identification tag for the component
   */
  NISTR: string;
  /**
   * Qty
   * X and Y Quantity
   * @maxItems 2
   * @minItems 2
   */
  qty: number[];
  /**
   * Elev
   * Elevation values for this component on the given floor
   * @maxItems 2
   * @minItems 2
   */
  elev: number[];
}

/** CreateComponentRequestApi */
export interface CreateComponentRequestApi {
  hazard_type: HazardType;
  /** Component */
  component:
    | ({
        risk_engine: "FLOOD";
      } & FloodComponent)
    | ({
        risk_engine: "SEISMIC";
      } & SeismicComponent)
    | ({
        risk_engine: "WIND";
      } & WindComponent);
}

/** CreateEngineRunRequestApi */
export interface CreateEngineRunRequestApi {
  /**
   * Asset Version
   * version of assessment
   */
  asset_version: string;
  /**
   * Hazard Id
   * list of hazard id's
   * @minItems 1
   */
  hazard_id: string[];
  /**
   * Shared Fate
   * Identifies if the engine run is shared fate
   * @default false
   */
  shared_fate?: boolean | null;
  /**
   * N Realizations
   * Describes how many realizations to run of the given hazards
   * @default 100
   */
  n_realizations?: number | null;
}

/** CreateHazardRequest */
export interface CreateHazardRequest {
  /**
   * Asset Id
   * Identifier for the asset this hazard effects.
   */
  asset_id?: string | null;
  /** Type of sub-risk */
  subhazard_type: SubHazardType;
  /**
   * Return Period
   * Hazard Return Period
   */
  return_period?: number | null;
  /**
   * The type of basis for the intensity measure
   * @default "RETURN_PERIOD"
   */
  frequency_metric?: FrequencyMetric;
  /** The Intensity Measure Type */
  intensity_measure: IntensityMeasureType;
  /**
   * Hazard Source
   * Declare where the hazard was calculated from
   */
  hazard_source?: string | null;
  /** The climate change scenario. Possible values : RCP_1P9, RCP_2P6, RCP_3P4, RCP_4P5, RCP_6P0, RCP_7P0, RCP_8P5, SSP1_1P9, SSP1_2P6, SSP2_4P5, SSP3_7P0, SSP5_8P5 */
  climate_scenario?: ClimateScenario | null;
  /**
   * Time Horizon
   * The time horizon of the hazard, in order to identify whether this is a hazard due to climate change or a current hazard
   * @min 0
   */
  time_horizon: number;
  /** Hazard Data */
  hazard_data:
    | SimpleHazardDistributionInput
    | SeismicHazardStandardDistribution
    | SeismicHazardCustomDistribution
    | SeismicEDPsHazard
    | WindHazardStandardDistribution
    | WindHazardCustomDistribution
    | FloodHazardStandardDistribution
    | WindHazardRealizations
    | AdjacencyHazardInfo;
  /** Any metadata associated to the hazard. */
  metadata?: HazardMetadata | null;
  /**
   * Assessment Id
   * Identifier for the assessment related to the hazard.
   */
  assessment_id: string;
}

/** CreateMultipleAssessmentsRequest */
export interface CreateMultipleAssessmentsRequest {
  /** Asset Ids */
  asset_ids: string[];
  /** Subhazard Types */
  subhazard_types: SubHazardType[];
  /** Time Horizons */
  time_horizons: number[];
  /** Climate Scenarios */
  climate_scenarios?: ClimateScenario[] | null;
  /** Risk Class */
  risk_class: number;
  /** Tags */
  tags?: string[] | null;
}

/** CreateParameterValueRequest */
export interface CreateParameterValueRequest {
  /** The ID of the parameter */
  parameter_id: ParameterId;
  /**
   * Metadata
   * Metadata of the parameter
   */
  metadata: object;
  /**
   * Values
   * List of parameter instances.
   */
  values: ParameterInstanceModel[];
}

/** CreateRiskParametersSharedFateRequest */
export interface CreateRiskParametersSharedFateRequest {
  repair: AppModelsRiskParametersRiskParametersSharedFateRepair;
  wind: AppModelsRiskParametersRiskParametersSharedFateWind;
  general: AppModelsRiskParametersRiskParametersSharedFateGeneral;
  impeding_factors: AppModelsRiskParametersRiskParametersSharedFateImpedingFactors;
  contents: AppModelsRiskParametersRiskParametersSharedFateContents;
  seismic: AppModelsRiskParametersRiskParametersSharedFateSeismic;
  business: AppModelsRiskParametersRiskParametersSharedFateBusiness;
}

/** CreateRiskRequestApi */
export interface CreateRiskRequestApi {
  general: AppModelsRiskParametersRiskParametersGeneral;
  repair?: AppModelsRiskParametersRiskParametersRepair | null;
  wind?: AppModelsRiskParametersRiskParametersWind | null;
  impeding_factors?: AppModelsRiskParametersRiskParametersImpedingFactors | null;
  contents?: AppModelsRiskParametersRiskParametersContents | null;
  seismic?: AppModelsRiskParametersRiskParametersSeismic | null;
  business?: AppModelsRiskParametersRiskParametersBusiness | null;
}

/** DataRecordModel */
export interface DataRecordModelInput {
  /** The type of output metric (percentile or aggregate) */
  type: OutputMetricType;
  /**
   * Name
   * A subgrouping of the output metric
   * @default ""
   */
  name?: string | number | null;
  /**
   * Value
   * The value of the output
   */
  value:
    | number
    | string
    | any[][]
    | (
        | ({
            curve_type: "ARCHETYPE_LOSS";
          } & ArchetypeLossCurveInput)
        | ({
            curve_type: "BASE";
          } & BaseCurveInput)
        | ({
            curve_type: "HAZARD";
          } & HazardCurveInput)
        | ({
            curve_type: "LOSS";
          } & LossCurveInput)
        | ({
            curve_type: "RISK_RATING";
          } & RiskRatingCurveInput)
      );
  /** The type of consequence that the output represents */
  data_key?: ConsequenceType | null;
  /**
   * Return Period
   * The return period of the output
   * @default 0
   */
  return_period?: number | null;
  /**
   * Percentile
   * The percentile associated to the output
   * @default 0
   */
  percentile?: number | null;
  /**
   * Asset Id
   * The asset ID that the output records relate to. This is used to differentiate output in group level assessments
   */
  asset_id?: string | null;
  /** Type of intensity measure that the output records relate to */
  intensity_measure?: IntensityMeasureType | null;
  /** Unit of the record */
  unit?: UnitType | null;
}

/** DataRecordModel */
export interface DataRecordModelOutput {
  /** The type of output metric (percentile or aggregate) */
  type: OutputMetricType;
  /**
   * Name
   * A subgrouping of the output metric
   * @default ""
   */
  name?: string | number | null;
  /**
   * Value
   * The value of the output
   */
  value:
    | number
    | string
    | any[][]
    | (
        | ({
            curve_type: "ARCHETYPE_LOSS";
          } & ArchetypeLossCurveOutput)
        | ({
            curve_type: "BASE";
          } & BaseCurveOutput)
        | ({
            curve_type: "HAZARD";
          } & HazardCurveOutput)
        | ({
            curve_type: "LOSS";
          } & LossCurveOutput)
        | ({
            curve_type: "RISK_RATING";
          } & RiskRatingCurveOutput)
      );
  /** The type of consequence that the output represents */
  data_key?: ConsequenceType | null;
  /**
   * Return Period
   * The return period of the output
   * @default 0
   */
  return_period?: number | null;
  /**
   * Percentile
   * The percentile associated to the output
   * @default 0
   */
  percentile?: number | null;
  /**
   * Asset Id
   * The asset ID that the output records relate to. This is used to differentiate output in group level assessments
   */
  asset_id?: string | null;
  /** Type of intensity measure that the output records relate to */
  intensity_measure?: IntensityMeasureType | null;
  /** Unit of the record */
  unit?: UnitType | null;
}

/** DefaultInputValueRule */
export interface DefaultInputValueRule {
  /** Value */
  value?: boolean | number | string | any[] | null;
  /** Specification of the parameter */
  condition?: Specification;
}

/** DownloadFileResponse */
export interface DownloadFileResponse {
  /**
   * Id
   * Main key to identify each file, cannot be repeated and should not be modified
   */
  id?: string;
  /**
   * Ref Id
   * Reference to asset/asset group
   */
  ref_id: string;
  /** The type of object the resource is linked to. */
  ref_type: ServiceReferenceTypeEnum;
  /**
   * The type of resource that the file represents
   * @default "GENERAL"
   */
  resource_type?: ResourceTypeEnum;
  /**
   * Description
   * Description of the file content
   */
  description?: string | null;
  /**
   * Metadata
   * Meta data about the file
   */
  metadata?: object | null;
  /**
   * File Name
   * Name of the file.
   */
  file_name: string;
  /**
   * File Extension
   * Extension of the file.
   */
  file_extension: string;
  /**
   * Content Type
   * Content-type of the file
   */
  content_type: string;
  /**
   * Created By
   * Email of the user that uploaded the file
   */
  created_by: string;
  /**
   * Created At
   * When the resource (file) was created
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated By
   * Email of the user that uploaded the file
   */
  updated_by?: string | null;
  /**
   * Updated At
   * When the resource (file) was created
   */
  updated_at?: string | null;
  /**
   * Presigned Url
   * Presigned url of the file
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  presigned_url: string;
}

/** Downtime */
export interface Downtime {
  /**
   * T0
   * First value of the downtime in days. It should be always zero.
   */
  t0: number;
  /**
   * Tf
   * Last value of the downtime in days.
   */
  tf: number;
  /**
   * Dt
   * Downtime step in days to build the downtime vector which is bounded between t0 and dt.
   */
  dt: number;
}

/** DowntimeConsequence */
export enum DowntimeConsequence {
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
  YEARS = "YEARS",
}

/** EditHazardInfoRequest */
export interface EditHazardInfoRequest {
  /**
   * Return Period
   * Hazard Return Period
   */
  return_period?: number | null;
  /** Hazard Data */
  hazard_data?:
    | SimpleHazardDistributionInput
    | SeismicHazardStandardDistribution
    | SeismicHazardCustomDistribution
    | SeismicEDPsHazard
    | WindHazardStandardDistribution
    | WindHazardCustomDistribution
    | FloodHazardStandardDistribution
    | WindHazardRealizations
    | AdjacencyHazardInfo
    | null;
  /**
   * Hazard Source
   * Declare where the hazard was calculated from
   */
  hazard_source?: string | null;
  /** Unit of the hazard intensity measure */
  unit?: UnitType | null;
}

/** EngineModelInput */
export interface EngineModelInput {
  /**
   *  Id
   * ID (uuid) of a valid asset
   */
  _id: string;
  /** Type of Hazard */
  hazard_type: HazardType;
  /**
   * Nbasement
   * Number of below-ground stories
   * @min 0
   */
  nBasement: number;
  /**
   * Nabove
   * Number of above-ground stories
   * @min 0
   */
  nAbove: number;
  /**
   * Nfloor
   * Total number of stories, including above and below ground
   * @exclusiveMin 0
   */
  nFloor: number;
  /**
   * Replacement Cost
   * Cost (USD) to replace the building if it is a total loss
   * @exclusiveMin 0
   */
  replacement_cost: number;
  /**
   * Replacement Time
   * Number of days to replace the building if it is a total loss
   * @exclusiveMin 0
   */
  replacement_time: number;
  /**
   * Eco
   * Factor to correct for the fact that the building is not 100% populated at all times
   */
  ECO?: number | null;
  /**
   * Ffe
   * Finished floor elevation
   */
  ffe?: number | null;
  /**
   * Floor Heights
   * Height of every story
   * @minItems 1
   */
  floor_heights: number[];
  /**
   * Floor Population
   * Number of occupants on each floor
   */
  floor_population?: number[] | null;
  /**
   * Floor Areas
   * Area of each floor [sq ft]
   */
  floor_areas: number[];
  /**
   * Floor Length
   * The floor length in ft. Length of list must be equal to the number of floors.
   * @minItems 1
   */
  floor_length: number[];
  /**
   * Floor Width
   * The floor width in ft. Length of list must be equal to the number of floors.
   * @minItems 1
   */
  floor_width: number[];
  /** The risk parameters for the assessment */
  risk_parameters: RiskParameters;
  /**
   * Components
   * The components for the assessment
   */
  Components: RiskEngineComponent[][];
}

/** EngineRunStatus */
export enum EngineRunStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

/** EngineRunsList */
export interface EngineRunsList {
  /**
   * Engine Ids
   * List of engine run ids of the Shared fate assessment
   * @minItems 1
   */
  engine_ids: string[];
}

/** FinanceMethodEnum */
export enum FinanceMethodEnum {
  PreArrangedCredit = "Pre-Arranged Credit",
  Insurance = "Insurance",
  PrivateLoans = "Private Loans",
  SBABackedLoans = "SBA-Backed Loans",
}

/** FloodComponent */
export interface FloodComponent {
  /**
   * Nistr
   * Identification tag for the component, as defined by FEMA P-58
   */
  NISTR: string;
  /**
   * Region
   * Region for this component.
   * @default "USA"
   */
  region?: string | null;
  /** Risk Engine */
  risk_engine: "FLOOD";
  /**
   * Nickname
   * Nickname for this version of the given component.
   */
  nickname?: string | null;
  /**
   * Repair sequence
   * Repair sequence for the component
   */
  seq: number[];
  /**
   * Number of damage states
   * Number of damage states
   */
  n_ds: number;
  /**
   * Long lead time
   * Long lead time for each damage state
   */
  long_lead: number[];
  /**
   * Fragility median
   * Median values of the fragility curves
   */
  theta_ds: number[][];
  /**
   * Fragility uncertainty
   * Beta values of the fragility curves
   */
  beta_ds: number[][];
  /**
   * Repair cost distribution
   * Distribution type for the repair cost curves
   */
  cds_fit: string[][];
  /**
   * Repair cost median
   * Median values for the repair cost curves
   */
  cds_mean: number[][][];
  /**
   * Repair cost uncertainty
   * Beta values of the repair cost curves
   */
  cds_beta: number[][];
  /**
   * Repair class
   * Repair class for each damage state
   */
  rds: number[];
  /**
   * Repair time distribution
   * Distribution type for the repair time curves
   */
  rds_fit: string[][];
  /**
   * Repair time median
   * Median values for the repair time curves
   */
  rds_mean: number[][][];
  /**
   * Repair time uncertainty
   * Beta values of the repair time curves
   */
  rds_beta: number[][];
  /**
   * Component
   * component
   */
  component: string;
  /**
   * Subgroup
   * subgroup
   */
  subgroup: string;
  /**
   * Compdesc
   * comment description
   */
  compdesc: string;
  /**
   * Parameter
   * EDP associated with the fragility curves for this component
   */
  param: string;
  /**
   * Parameter units
   * Units associated with the 'Parameter'
   */
  param_units: string;
  /**
   * Normative quantities
   * Average quantities associated with different occupancy types
   */
  q_norm: number[][];
  /**
   * Demand location
   * Location of the demand (0 for floor, 1 for ceiling)
   */
  demand_loc: number;
  /**
   * Repair cost cutoffs
   * Cutoff values associated with the median values for the repair cost curves
   */
  cds_cutoff: number[][][];
  /**
   * Repair time cutoffs
   * Cutoff values associated with the median values for the repair time curves
   */
  rds_cutoff: number[][][];
  /**
   * Injury median
   * Median values for the injury curves
   */
  ids_median: number[][];
  /**
   * Injury uncertainty
   * Beta values for the injury curves
   */
  ids_beta: number[][];
  /**
   * Fatality median
   * Median values for the fatality curves
   */
  dds_median: number[][];
  /**
   * Fatality uncertainty
   * Beta values for the fatality curves
   */
  dds_beta: number[][];
  /**
   * Affected area
   * Affected area for each damage state
   */
  affected_area: number[][];
  /**
   * Long lead flag
   * Booolean indicator for whether or not the damage state has a long lead time
   */
  long_lead_flag: boolean[] | null;
  /**
   * Probability of damage state
   * Probably of the damage state occurring. This is only relevant for mutually-exclusive damage states, which are rare
   */
  pds: number[][];
  /**
   * Correlation coefficient
   * Correlation coefficient of component damage state with other components on same floor
   */
  correlated: number;
  /**
   * Comments
   * comments
   */
  comments: string;
  /**
   * Description
   * description
   */
  description: string;
  /**
   * Custom
   * custom
   * @default false
   */
  custom?: boolean | null;
  /**
   * Database Access
   * Database access
   */
  database_access: string[];
  /**
   * Dataquality
   * data quality
   */
  dataquality: string;
  /**
   * Datarelevance
   * data relevance
   */
  datarelevance: string;
  /**
   * Directional
   * directional
   */
  directional?: boolean | null;
  /**
   * Docquality
   * docquality
   */
  docquality: string;
  /**
   * Dsdesc
   * Database access
   */
  DSdesc: string[][];
  /**
   * Group
   * group
   */
  group: string;
  /**
   * Hazards
   * hazards
   */
  hazards: string[];
  /**
   * Heir
   * heir
   */
  heir: string;
  /**
   * Rationality
   * rationality
   */
  rationality: string;
  /**
   * Repairdesc
   * repairdesc
   */
  repairdesc: string[][];
  /**
   * Tpr
   * tpr
   */
  tpr: number;
}

/** FloodHazardStandardDistribution */
export interface FloodHazardStandardDistribution {
  /** Distribution from which to sample. Allowed values Simple, Normal, Lognormal, Custom,EDP */
  im_distribution: HazardDistribution;
  /**
   * Flooddepth Theta
   * Median flood depth (Units: Feet)
   * @min 0
   */
  flooddepth_theta: number;
  /**
   * Flooddepth Beta
   * Uncertainty on flood depth (e.g. uncertainty for what exactly defines '100-year flood')
   * @min 0
   */
  flooddepth_beta: number;
}

/** FrequencyMetric */
export enum FrequencyMetric {
  RETURN_PERIOD = "RETURN_PERIOD",
  PERCENTILE = "PERCENTILE",
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HazardCategory */
export enum HazardCategory {
  GEOPHYSICAL = "GEOPHYSICAL",
  HYDROLOGICAL = "HYDROLOGICAL",
  METEOROLOGICAL = "METEOROLOGICAL",
  CLIMATOLOGICAL = "CLIMATOLOGICAL",
  ACCIDENTAL = "ACCIDENTAL",
  INTENTIONAL = "INTENTIONAL",
  ADJACENCY = "ADJACENCY",
}

/** HazardCurve */
export interface HazardCurveInput {
  /**
   * Curve Type
   * @default "HAZARD"
   */
  curve_type?: "HAZARD";
  /**
   * Units
   * The units of the curve.
   * @maxItems 2
   * @minItems 2
   */
  units: any[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /**
   * Dimension
   * Dimension of the hazard curve.
   * @maxItems 2
   * @minItems 2
   */
  dimension: any[];
}

/** HazardCurve */
export interface HazardCurveOutput {
  /**
   * Curve Type
   * @default "HAZARD"
   */
  curve_type?: "HAZARD";
  /**
   * Units
   * The units of the curve.
   */
  units: string[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /**
   * Dimension
   * Dimension of the hazard curve.
   * @maxItems 2
   * @minItems 2
   */
  dimension: any[];
}

/** HazardDistribution */
export enum HazardDistribution {
  Lognormal = "Lognormal",
  Normal = "Normal",
  Custom = "Custom",
  Simple = "Simple",
  EDP = "EDP",
  SIMPLE = "SIMPLE",
  NORMAL = "NORMAL",
  CUSTOM = "CUSTOM",
  LOGNORMAL = "LOGNORMAL",
}

/** HazardImportRequest */
export interface HazardImportRequest {
  /** Time Horizon */
  time_horizon?: number | null;
  /** Climate Scenario */
  climate_scenario?: string | null;
  /**
   * Buffer Distance
   * The buffer distance in kilometers. If not provided it uses the default value set for the hazard.
   */
  buffer_distance?: number | null;
  /**
   * Job Number
   * The job number to charge the costs to in case a paid service is used.
   */
  job_number?: string | null;
  /**
   * Model Id
   * The identifier of the hazard model that is requested. If blank, the default for the hazard is used.
   */
  model_id?: string | null;
}

/** HazardMetadata */
export interface HazardMetadata {
  /**
   * Buffer Distance
   * The distance for creating the buffer to extract the hazard information.
   */
  buffer_distance?: number | null;
  /**
   * Buffer Geometry
   * The geometry of the buffer used for querying the hazard data.
   */
  buffer_geometry?: object | null;
}

/** HazardResponse */
export interface HazardResponse {
  /**
   * Asset Id
   * Identifier for the asset this hazard effects.
   */
  asset_id?: string | null;
  /** Type of sub-risk */
  subhazard_type: SubHazardType;
  /**
   * Return Period
   * Hazard Return Period
   */
  return_period?: number | null;
  /**
   * The type of basis for the intensity measure
   * @default "RETURN_PERIOD"
   */
  frequency_metric?: FrequencyMetric;
  /** The Intensity Measure Type */
  intensity_measure: IntensityMeasureType;
  /**
   * Hazard Source
   * Declare where the hazard was calculated from
   */
  hazard_source?: string | null;
  /** The climate change scenario. Possible values : RCP_1P9, RCP_2P6, RCP_3P4, RCP_4P5, RCP_6P0, RCP_7P0, RCP_8P5, SSP1_1P9, SSP1_2P6, SSP2_4P5, SSP3_7P0, SSP5_8P5 */
  climate_scenario?: ClimateScenario | null;
  /**
   * Time Horizon
   * The time horizon of the hazard, in order to identify whether this is a hazard due to climate change or a current hazard
   * @min 0
   */
  time_horizon: number;
  /** Hazard Data */
  hazard_data:
    | SimpleHazardDistributionOutput
    | SeismicHazardStandardDistribution
    | SeismicHazardCustomDistribution
    | SeismicEDPsHazard
    | WindHazardStandardDistribution
    | WindHazardCustomDistribution
    | FloodHazardStandardDistribution
    | WindHazardRealizations
    | AdjacencyHazardInfo;
  /** Any metadata associated to the hazard. */
  metadata?: HazardMetadata | null;
  /**
   * Assessment Id
   * Identifier for the assessment related to the hazard.
   */
  assessment_id: string;
  /**
   * Id
   * Main key to identify each Hazard, cannot be repeated and should not be modified
   */
  id: string;
  /**
   * Resources
   * List of resources that are related to the Hazard
   */
  resources?: ResourceResponse[] | null;
  /**
   * Created At
   * When the hazard was created
   */
  created_at?: string | null;
  /**
   * Created By
   * Who created the hazard
   */
  created_by?: string | null;
  /**
   * Updated At
   * When the hazard info was updated last time
   */
  updated_at?: string | null;
  /**
   * Updated By
   * Who updated the hazard info last time
   */
  updated_by?: string | null;
}

/** HazardType */
export enum HazardType {
  SEISMIC = "SEISMIC",
  WIND = "WIND",
  FLOOD = "FLOOD",
  WILDFIRE = "WILDFIRE",
  TORNADO = "TORNADO",
  ADJACENCY = "ADJACENCY",
  ACCIDENTAL = "ACCIDENTAL",
  TSUNAMI = "TSUNAMI",
  LANDSLIDE = "LANDSLIDE",
  VOLCANO = "VOLCANO",
  AVALANCHE = "AVALANCHE",
  DROUGHT = "DROUGHT",
  HEAT = "HEAT",
  COLD = "COLD",
  HAIL = "HAIL",
  DUST_STORM = "DUST_STORM",
  SNOW = "SNOW",
  ICE_STORM = "ICE_STORM",
  THUNDERSTORM = "THUNDERSTORM",
  INTENTIONAL = "INTENTIONAL",
}

/** InjuryConsequence */
export enum InjuryConsequence {
  MINOR = "MINOR",
  MAJOR = "MAJOR",
  SEVERE = "SEVERE",
  VERY_SEVER = "VERY_SEVER",
  FATALITY = "FATALITY",
}

/** InputParameter */
export interface InputParameter {
  /**
   * Id
   * Unique identifier for the field
   */
  id: string;
  /** Unit of the parameter */
  unit?: UnitType | null;
  /**
   * Value
   * Value of the parameter
   */
  value?: null;
  /**
   * Default Value
   * Default value of the parameter
   */
  default_value?: null;
  field_type: InputParameterFieldType;
  /**
   * Label
   * Label to display for the field
   */
  label: string;
  /**
   * Description
   * Description of the field
   */
  description?: string | null;
  /**
   * Options
   * List of options
   * @default []
   */
  options?: InputParameterOption[] | null;
  /**
   * Visible
   * Whether the field is visible or not
   * @default true
   */
  visible?: boolean | null;
  /**
   * Disabled
   * Whether the field is disabled or not
   * @default false
   */
  disabled?: boolean | null;
  /**
   * Help Text
   * Help text for the field
   */
  help_text?: string | null;
  /**
   * Required
   * Whether the field is required or not
   * @default false
   */
  required?: boolean | null;
  /** Visibility rule for the field */
  visibility_condition?: Specification | null;
  /** Validation rule for the field */
  validation?: Specification | null;
  /**
   * Default Value Rules
   * Default value for the field
   */
  default_value_rules?: DefaultInputValueRule[] | null;
  /**
   * Read Only
   * Whether the field is read-only or not
   * @default false
   */
  read_only?: boolean | null;
  /**
   * Adjacency List
   * List of fields that are affected by this field
   */
  adjacency_list?: object[] | null;
}

/** InputParameterFieldType */
export enum InputParameterFieldType {
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
  NUMBER = "NUMBER",
  FLOAT = "FLOAT",
  DATE = "DATE",
  DATETIME = "DATETIME",
  SINGLESELECT = "SINGLESELECT",
  MULTISELECT = "MULTISELECT",
  GEOCOORDINATES = "GEOCOORDINATES",
  ARCHETYPE = "ARCHETYPE",
  DICTIONARY = "DICTIONARY",
}

/** InputParameterOption */
export interface InputParameterOption {
  /**
   * Value
   * Value of the option
   */
  value?: boolean | number | string;
  /**
   * Label
   * Label to display for the option
   */
  label?: string;
  /**
   * Description
   * Description of the option
   */
  description?: string | null;
  /**
   * Image Url
   * Image URL for the option
   */
  image_url?: string | null;
  /** Validation rule */
  validation?: Specification | null;
}

/** IntensityMeasure */
export interface IntensityMeasure {
  /** The Intensity Measure type */
  intensity_measure: IntensityMeasureType;
  /** The unit of the Intensity Measure */
  unit: UnitType;
}

/** IntensityMeasureType */
export enum IntensityMeasureType {
  FLOOD_DEPTH = "FLOOD_DEPTH",
  FLAME_LENGTH = "FLAME_LENGTH",
  ADJACENT_FLAME_LENGTH = "ADJACENT_FLAME_LENGTH",
  EMBER_COUNTS = "EMBER_COUNTS",
  MAX3SGUST = "MAX_3S_GUST",
  WIND_SPEED = "WIND_SPEED",
  PEAK_GROUND_ACCELERATION = "PEAK_GROUND_ACCELERATION",
  PEAK_GROUND_VELOCITY = "PEAK_GROUND_VELOCITY",
  HEAT_INDEX = "HEAT_INDEX",
  EXTREME_ANNUAL_DRY_BULB = "EXTREME_ANNUAL_DRY_BULB",
  EXTREME_ANNUAL_WET_BULB = "EXTREME_ANNUAL_WET_BULB",
  DISTANCE = "DISTANCE",
  AGGREGATE = "AGGREGATE",
}

/** Job */
export interface Job {
  /**
   * Id
   * The unique identifier of the job.
   */
  id?: string;
  /** The type of job to be executed, e.g. HAZARD_IMPORT. */
  type: JobType;
  /** The type of object the job is linked to. */
  ref_type: ServiceReferenceTypeEnum;
  /**
   * Ref Id
   * Reference to the parent object requestion the job, e.g. ASSESSMENT.
   */
  ref_id: string;
  /**
   * The status of the job.
   * @default "RUNNING"
   */
  status?: StatusEnum | null;
  /**
   * Created At
   * Timestamp in UTC when the job was created.
   * @format date-time
   */
  created_at: string;
  /**
   * Created By
   * Who submitted the job.
   */
  created_by: string;
  /**
   * Updated At
   * Timestamp in UTC when the job was updated.
   */
  updated_at?: string | null;
  /**
   * Details
   * Message explaining more about the job, e.g. error message if it failed.
   */
  details?: string | null;
}

/** JobType */
export enum JobType {
  HAZARD_IMPORT = "HAZARD_IMPORT",
  ARCHETYPE_IMPORT = "ARCHETYPE_IMPORT",
  PARAMETER_IMPORT = "PARAMETER_IMPORT",
}

/** Library */
export interface Library {
  /**
   * Updated At
   * Timestamp indicating the last time the library was updated
   */
  updated_at?: string | null;
  /**
   * Updated By
   * Email of the user who last updated the library
   */
  updated_by?: string | null;
  /**
   * Id
   * Unique identifier for the library
   */
  id?: string;
  /**
   * Identifier
   * Identifier of the library
   */
  identifier: HazardType | ParameterId;
  /**
   * Major Version
   * Major version number of the library
   * @min 1
   */
  major_version: number;
  /** Type of library */
  type: LibraryType;
  /**
   * Defines the scope of the library. It can be 'GLOBAL' or 'ORGANIZATION'.
   * @default "GLOBAL"
   */
  scope?: Scope;
  /**
   * Created At
   * Timestamp indicating when the library was created
   * @format date-time
   */
  created_at?: string;
  /**
   * Created By
   * Email of the user who created the library
   */
  created_by: string;
}

/** LibraryType */
export enum LibraryType {
  ARCHETYPE = "ARCHETYPE",
  PARAMETER = "PARAMETER",
}

/** Likelihood */
export enum Likelihood {
  Value011 = "0.1.1",
  Value110 = "1.10",
  Value10100 = "10.100",
  Value1001000 = "100.1000",
  Value100010000 = "1000.10000",
  Value10000100000 = "10000.100000",
  Value1000001000000 = "100000.1000000",
}

/** LikelihoodDetailed */
export enum LikelihoodDetailed {
  Value0103 = "0.1.0.3",
  Value031 = "0.3.1",
  Value13 = "1.3",
  Value310 = "3.10",
  Value1030 = "10.30",
  Value30100 = "30.100",
  Value100300 = "100.300",
  Value3001000 = "300.1000",
  Value10003000 = "1000.3000",
  Value300010000 = "3000.10000",
  Value1000030000 = "10000.30000",
  Value30000100000 = "30000.100000",
  Value100000300000 = "100000.300000",
  Value3000001000000 = "300000.1000000",
}

/** ListInputParameters */
export interface ListInputParameters {
  /**
   * Input Parameters
   * List of input parameters
   */
  input_parameters?: BaseInputParameter[];
}

/** LossCurve */
export interface LossCurveInput {
  /**
   * Curve Type
   * @default "LOSS"
   */
  curve_type?: "LOSS";
  /**
   * Units
   * The units of the curve.
   * @maxItems 2
   * @minItems 2
   */
  units: any[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /**
   * Dimension
   * Dimension of the loss curve.
   * @maxItems 2
   * @minItems 2
   */
  dimension: any[];
  /**
   * Percentile
   * Percentile estimate that the loss curve represents
   * @min 0
   * @max 100
   * @default 50
   */
  percentile?: number;
}

/** LossCurve */
export interface LossCurveOutput {
  /**
   * Curve Type
   * @default "LOSS"
   */
  curve_type?: "LOSS";
  /**
   * Units
   * The units of the curve.
   */
  units: string[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /**
   * Dimension
   * Dimension of the loss curve.
   * @maxItems 2
   * @minItems 2
   */
  dimension: any[];
  /**
   * Percentile
   * Percentile estimate that the loss curve represents
   * @min 0
   * @max 100
   * @default 50
   */
  percentile?: number;
}

/** MapLayerInfo */
export interface MapLayerInfo {
  /**
   * Id
   * Unique identifier (uuid) for the map layer.
   */
  id: string;
  /**
   * Name
   * The layer name.
   */
  name: string;
  /**
   * Created By
   * User who uploaded the map layer.
   */
  created_by: string;
  /**
   * Ref Id
   * Id (uuid) of the object (asset/ group of assets) to which the map layer is assigned.
   */
  ref_id: string;
  /**
   * Hazard Type
   * The type of hazard the map data relates to. If None, then it is a generic layer.
   */
  hazard_type: RiskRatingHazardsEnum | AllHazardsEnum;
  /** Type of the object to which the map layer is assigned. Allowed values: ASSET, GROUP  */
  ref_type: ReferenceTypeEnum;
  /**
   * Description
   * Brief description of the map layer.
   */
  description?: string | null;
  /**
   * Created At
   * When the map layer was uploaded.
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * When the new version of the map layer was uploaded.
   */
  updated_at?: string | null;
  /**
   * Updated By
   * User who uploaded the new version of map layer.
   */
  updated_by?: string | null;
  /** The status of the map layer. Allowed values: IN_PROGRESS, COMPLETED, ERROR */
  status: EngineRunStatus;
  /** Message */
  message: string;
  /**
   * Upload Id
   * The upload identifier generated by the Mapbox API.
   */
  upload_id: string;
}

/** MapLayerUpdateRequest */
export interface MapLayerUpdateRequest {
  /**
   * Name
   * The layer name.
   */
  name?: string | null;
  /**
   * Description
   * Brief description of the map layer.
   */
  description?: string | null;
  /**
   * Hazard Type
   * The type of hazard the map data relates to. If None, then it is a generic layer.
   */
  hazard_type?: RiskRatingHazardsEnum | AllHazardsEnum | null;
}

/** MissileEnvEnum */
export enum MissileEnvEnum {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

/** OutputMetricType */
export enum OutputMetricType {
  AGG = "AGG",
  FID = "FID",
  FIA = "FIA",
  PERCENTILE = "PERCENTILE",
  AAL = "AAL",
  NUM_NINES = "NUM_NINES",
  HAZARD_LOSS_CURVE = "HAZARD_LOSS_CURVE",
  ADJUSTED_LOSS_CURVE = "ADJUSTED_LOSS_CURVE",
  MONETARY_LOSS_CURVE = "MONETARY_LOSS_CURVE",
  REPLACEMENT_COST_CURVE = "REPLACEMENT_COST_CURVE",
  REPLACEMENT_COST_AAL = "REPLACEMENT_COST_AAL",
  LOSS_CURVE = "LOSS_CURVE",
  HAZARD_CURVE = "HAZARD_CURVE",
  EDP_CURVE = "EDP_CURVE",
  RISK_RATING = "RISK_RATING",
  HAZARD = "HAZARD",
  LOSS = "LOSS",
  EXCEPTION = "EXCEPTION",
}

/** PaginatedAssessmentInfo */
export interface PaginatedAssessmentInfo {
  /**
   * Results
   * Page of results requested
   */
  results: AssessmentResponse[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/** PaginatedEngineRunInfo */
export interface PaginatedEngineRunInfo {
  /**
   * Results
   * Page of results requested
   */
  results: ReadEngineRun[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/** PaginatedJobInfo */
export interface PaginatedJobInfo {
  /**
   * Results
   * Page of results requested
   */
  results: Job[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/**
 * PaginationResponse
 * This model should be extended to create a standard paginated response for our apis.
 */
export interface PaginationResponse {
  /**
   * Results
   * Page of results requested
   */
  results: any[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/** PaginationResponse[ArchetypeResponse] */
export interface PaginationResponseArchetypeResponse {
  /**
   * Results
   * Page of results requested
   */
  results: ArchetypeResponse[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/** PaginationResponse[AssessmentResponse] */
export interface PaginationResponseAssessmentResponse {
  /**
   * Results
   * Page of results requested
   */
  results: AssessmentResponse[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/** PaginationResponse[HazardResponse] */
export interface PaginationResponseHazardResponse {
  /**
   * Results
   * Page of results requested
   */
  results: HazardResponse[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/** PaginationResponse[ParameterValueResponse] */
export interface PaginationResponseParameterValueResponse {
  /**
   * Results
   * Page of results requested
   */
  results: ParameterValueResponse[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/** PaginationResponse[RiskEngineResponse] */
export interface PaginationResponseRiskEngineResponse {
  /**
   * Results
   * Page of results requested
   */
  results: RiskEngineResponse[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   */
  cursor?: string | null;
}

/** ParameterAttributes */
export interface ParameterAttributes {
  /**
   * Attrs
   * The attributes of the parameter
   */
  attrs: object;
}

/** ParameterId */
export type ParameterId = "HEAT_DELTA_TEMPERATURE";

/** ParameterInstanceModel */
export interface ParameterInstanceModel {
  /** The ID of the parameter */
  parameter_id: ParameterId;
  /**
   * Type
   * Type of the parameter instance
   */
  type: string;
  /**
   * Unit
   * Unit of the parameter instance
   */
  unit: any;
  /**
   * Value
   * Value of the parameter instance
   */
  value: any;
  /** Intensity measure */
  intensity_measure?: IntensityMeasureType | null;
}

/** ParameterValueResponse */
export interface ParameterValueResponse {
  /**
   * Id
   * Main key to identify each parameter
   */
  id: string;
  /** The ID of the parameter */
  parameter_id: ParameterId;
  /**
   * Major Version
   * Major version of the parameter
   * @min 1
   */
  major_version: number;
  /**
   * Minor Version
   * Minor version of the parameter
   * @min 1
   */
  minor_version: number;
  /**
   * Defines the scope of the parameter. It can be 'GLOBAL' or 'ORGANIZATION'.
   * @default "GLOBAL"
   */
  scope?: Scope;
  /**
   * Metadata
   * Metadata of the parameter
   */
  metadata: object;
  /**
   * Values
   * List of parameter instances.
   */
  values: ParameterInstanceModel[];
  /**
   * Created At
   * When the parameter was created
   * @format date-time
   */
  created_at?: string;
  /**
   * Created By
   * User who created the parameter
   */
  created_by?: string | null;
  /**
   * Updated At
   * When the parameter was last updated
   */
  updated_at?: string | null;
  /**
   * Updated By
   * User who last updated the parameter
   */
  updated_by?: string | null;
}

/** PercentageRange */
export enum PercentageRange {
  Value13 = "1.3",
  Value310 = "3.10",
  Value1030 = "10.30",
  Value30100 = "30.100",
}

/** RCPScenarioEnum */
export enum RCPScenarioEnum {
  Value19 = "1.9",
  Value26 = "2.6",
  Value34 = "3.4",
  Value45 = "4.5",
  Value60 = "6.0",
  Value70 = "7.0",
  Value85 = "8.5",
}

/** RailAdjacencyRiskRequestApi */
export interface RailAdjacencyRiskRequestApi {
  /**
   * Distance
   * Shortest distance from site to railroad centerline.
   */
  distance: number;
  /**
   * Site Segment Length
   * Site segment length.
   */
  site_segment_length: number;
}

/** RatingHazardsCount */
export interface RatingHazardsCount {
  /** The type of hazard */
  hazard: RiskRatingHazardsEnum;
  /**
   * Counts
   * Asset ids for each of the level of risks
   */
  counts: Record<string, string[]>;
  /**
   * Risk Sort Score
   * The sort ranking for top hazards
   */
  risk_sort_score: number;
}

/** RatingInfo */
export interface RatingInfo {
  /**
   * Ref Id
   * Asset or group Id.
   */
  ref_id: string;
  /** If ref_id is an ASSET or GROUP */
  ref_type: ReferenceTypeEnum;
  /** The type of hazard */
  hazard?: RiskRatingHazardsEnum | null;
  /** The type of consequence */
  consequence: RiskRatingConsequenceType;
  /** The risk rating score */
  risk_rating: RiskRatingEnum;
  /**
   * Risk Sort Score
   * The sort ranking for high tide risk
   */
  risk_sort_score: number;
}

/** RatingsDict */
export interface RatingsDict {
  /** The risk class of the assessment to which the rating is associated to. */
  confidence?: RiskClassEnum | null;
  /** Risk Rating */
  risk_rating: RiskRatingEnum;
  /**
   * Level of risk that an organization is willing to accept while pursuing its objectives
   * @default "NEUTRAL"
   */
  apetite?: RiskAppetite | null;
  /**
   * Likelihood
   * Likelihood (time range) of the risk event occurring.
   */
  likelihood?: Likelihood | LikelihoodDetailed | null;
  /**
   * Consequence
   * Effect (downtime, injury, or a percentage range indicating the impact) of the risk event for a particular hazard and consequence (e.g. downtime, life_safety etc ...)
   */
  consequence?:
    | DowntimeConsequence
    | InjuryConsequence
    | PercentageRange
    | null;
}

/** ReadEngineRun */
export interface ReadEngineRun {
  /**
   * Asset Version
   * version of assessment
   */
  asset_version: string;
  /**
   * Hazard Id
   * list of hazard id's
   * @minItems 1
   */
  hazard_id: string[];
  /**
   * Shared Fate
   * Identifies if the engine run is shared fate
   * @default false
   */
  shared_fate?: boolean | null;
  /**
   * N Realizations
   * Describes how many realizations to run of the given hazards
   * @default 100
   */
  n_realizations?: number | null;
  /**
   * Id
   * Main key to identify each engine run
   */
  id: string;
  /**
   * Completed Hazard Ids
   * List of all hazards completed
   * @default []
   */
  completed_hazard_ids?: string[];
  /**
   * Failed Hazard Ids
   * List of all hazards failed
   * @default []
   */
  failed_hazard_ids?: string[];
  /**
   * Status
   * status of the engine run
   * @default "IN_PROGRESS"
   */
  status?: string;
  /**
   * Message
   * Message during engine run
   */
  message: string;
  /**
   * Created Date
   * When this engine run was created
   */
  created_date?: string | null;
}

/** RecoveryPlotRequestApi */
export interface RecoveryPlotRequestApi {
  /**
   * Repair Capacity
   * Number of assets that the client can repair in parallel.
   * @exclusiveMin 0
   */
  repair_capacity: number;
  /**
   * Engine Ids
   * List of engine run ids of the Shared fate assessment
   * @minItems 1
   */
  engine_ids: string[];
}

/** RecoveryPlotResponseApi */
export interface RecoveryPlotResponseApi {
  /**
   * Repair Capacity
   * Number of assets that the client can repair in parallel.
   * @exclusiveMin 0
   */
  repair_capacity: number;
  /**
   * Engine Ids
   * List of engine run ids of the Shared fate assessment
   * @minItems 1
   */
  engine_ids: string[];
  /** Information to build downtime vector */
  downtime: Downtime;
  /**
   * Fraction Area Restored
   * Fraction of squared footage restored (median value across the realizations). Notice that the length of this vector has to be the same as the downtime vector
   */
  fraction_area_restored: number[];
  /**
   * Return Period
   * Return period
   */
  return_period: number;
  /**
   * Hazard Ids Processed
   * List of the hazard ids taken into account for the recovery plot creation for a given return period
   */
  hazard_ids_processed: string[];
  /**
   * Engine Run Ids Processed
   * List of the engine runs ids taken into account for the recovery plot creation for a given return period
   */
  engine_run_ids_processed: string[];
  /**
   * Created At
   * When the recovery plot was created
   * @format date-time
   */
  created_at: string;
  /**
   * Created By
   *  Email of the user that submitted the creation of the recovery plot
   */
  created_by: string;
}

/** ReferenceIds */
export interface ReferenceIds {
  /**
   * Ref Ids
   * A list of only asset ids or group ids to evaluate across
   * @minItems 1
   */
  ref_ids: string[];
}

/** ReferenceTypeEnum */
export enum ReferenceTypeEnum {
  ASSET = "ASSET",
  GROUP = "GROUP",
}

/** ResourceResponse */
export interface ResourceResponse {
  /**
   * Id
   * Main key to identify each file, cannot be repeated and should not be modified
   */
  id?: string;
  /**
   * Ref Id
   * Reference to asset/asset group
   */
  ref_id: string;
  /** The type of object the resource is linked to. */
  ref_type: ServiceReferenceTypeEnum;
  /**
   * The type of resource that the file represents
   * @default "GENERAL"
   */
  resource_type?: ResourceTypeEnum;
  /**
   * Description
   * Description of the file content
   */
  description?: string | null;
  /**
   * Metadata
   * Meta data about the file
   */
  metadata?: object | null;
  /**
   * File Name
   * Name of the file.
   */
  file_name: string;
  /**
   * File Extension
   * Extension of the file.
   */
  file_extension: string;
  /**
   * Content Type
   * Content-type of the file
   */
  content_type: string;
  /**
   * Created By
   * Email of the user that uploaded the file
   */
  created_by: string;
  /**
   * Created At
   * When the resource (file) was created
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated By
   * Email of the user that uploaded the file
   */
  updated_by?: string | null;
  /**
   * Updated At
   * When the resource (file) was created
   */
  updated_at?: string | null;
}

/** ResourceTypeEnum */
export enum ResourceTypeEnum {
  GENERAL = "GENERAL",
  MAP_LAYER_IMAGE = "MAP_LAYER_IMAGE",
  MAP_LAYER_RASTER = "MAP_LAYER_RASTER",
}

/** RiskAppetite */
export enum RiskAppetite {
  NEUTRAL = "NEUTRAL",
  AVERSE = "AVERSE",
  TOLERANT = "TOLERANT",
}

/** RiskClassEnum */
export enum RiskClassEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/** RiskEngineComponent */
export interface RiskEngineComponent {
  /** Nistr */
  NISTR: string;
  /** Qty */
  Qty: number[];
  /** Elev */
  Elev: number[];
}

/** RiskEngineResponse */
export interface RiskEngineResponse {
  /**
   * Id
   * Risk engine ID
   */
  id: string;
  /**
   * Version
   * Risk engine version
   */
  version: string;
  /**
   * Name
   * Risk engine name
   */
  name: string;
  /**
   * Description
   * Risk engine description
   */
  description: string;
  /** Hazard type */
  hazard_type: HazardType;
  /** Risk class */
  risk_class: RiskClassEnum;
}

/** RiskEngineUrlOutput */
export interface RiskEngineUrlOutput {
  /**
   * Url
   * Presigned url to download json file containing the raw output of the assessment associated to the riskengine id
   */
  url: string;
}

/** RiskParameters */
export interface RiskParameters {
  general: AppModelsRiskParametersRiskParametersGeneral;
  repair?: AppModelsRiskParametersRiskParametersRepair | null;
  wind?: AppModelsRiskParametersRiskParametersWind | null;
  impeding_factors?: AppModelsRiskParametersRiskParametersImpedingFactors | null;
  contents?: AppModelsRiskParametersRiskParametersContents | null;
  seismic?: AppModelsRiskParametersRiskParametersSeismic | null;
  business?: AppModelsRiskParametersRiskParametersBusiness | null;
}

/** RiskParametersResponse */
export interface RiskParametersResponse {
  general: AppModelsRiskParametersRiskParametersGeneral;
  repair?: AppModelsRiskParametersRiskParametersRepair | null;
  wind?: AppModelsRiskParametersRiskParametersWind | null;
  impeding_factors?: AppModelsRiskParametersRiskParametersImpedingFactors | null;
  contents?: AppModelsRiskParametersRiskParametersContents | null;
  seismic?: AppModelsRiskParametersRiskParametersSeismic | null;
  business?: AppModelsRiskParametersRiskParametersBusiness | null;
  /**
   * Id
   * Main key to identify Risk parameter
   */
  id: string;
  /**
   * Assessment Id
   * Main key to identify each assessment tied to this risk response, cannot be repeated and should not be modified
   */
  assessment_id: string;
}

/** RiskParametersSharedFateResponse */
export interface RiskParametersSharedFateResponse {
  repair: AppModelsRiskParametersRiskParametersSharedFateRepair;
  wind: AppModelsRiskParametersRiskParametersSharedFateWind;
  general: AppModelsRiskParametersRiskParametersSharedFateGeneral;
  impeding_factors: AppModelsRiskParametersRiskParametersSharedFateImpedingFactors;
  contents: AppModelsRiskParametersRiskParametersSharedFateContents;
  seismic: AppModelsRiskParametersRiskParametersSharedFateSeismic;
  business: AppModelsRiskParametersRiskParametersSharedFateBusiness;
  /**
   * Id
   * Uuid to identify each Shared Fate Risk parameters
   */
  id: string;
  /**
   * Assessment Id
   * Assessment id of the shared fate assessment to which the risk parameters are created for.
   */
  assessment_id: string;
}

/** RiskRatingBatchRequest */
export interface RiskRatingBatchRequest {
  /**
   * Ref Ids
   * A list of only asset ids or group ids to evaluate across
   * @minItems 1
   */
  ref_ids: string[];
  /**
   * Hazards
   * A list of hazards for which the high-tide needs to be returned.
   */
  hazards?: RiskRatingHazardsEnum[] | null;
  /**
   * Consequences
   * A list of consequences for which the high-tide needs to be returned.
   */
  consequences?: RiskRatingConsequenceType[] | null;
}

/** RiskRatingConsequenceType */
export enum RiskRatingConsequenceType {
  ECONOMIC_LOSS = "ECONOMIC_LOSS",
  DOWNTIME = "DOWNTIME",
  INVENTORY_LOSS = "INVENTORY_LOSS",
  LIFE_SAFETY = "LIFE_SAFETY",
  AGGREGATE_LIFE_SAFETY = "AGGREGATE_LIFE_SAFETY",
  HEALTH_AND_WELLNESS = "HEALTH_AND_WELLNESS",
  DAMAGE = "DAMAGE",
  SEVERE_WEATHER_WARNING = "SEVERE_WEATHER_WARNING",
  HAZARD_RATING = "HAZARD_RATING",
}

/** RiskRatingCurve */
export interface RiskRatingCurveInput {
  /**
   * Curve Type
   * @default "RISK_RATING"
   */
  curve_type?: "RISK_RATING";
  /**
   * Units
   * The units of the curve.
   * @maxItems 2
   * @minItems 2
   */
  units: any[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /**
   * Dimension
   * Dimension of the curve
   * @maxItems 2
   * @minItems 2
   */
  dimension: any[];
  /**
   * Percentile
   * Percentile estimate that the curve represents
   * @min 0
   * @max 100
   * @default 50
   */
  percentile?: number;
}

/** RiskRatingCurve */
export interface RiskRatingCurveOutput {
  /**
   * Curve Type
   * @default "RISK_RATING"
   */
  curve_type?: "RISK_RATING";
  /**
   * Units
   * The units of the curve.
   */
  units: string[];
  /**
   * Curve
   * A list of DataPair objects representing the curve.
   */
  curve: any[][];
  /**
   * Dimension
   * Dimension of the curve
   * @maxItems 2
   * @minItems 2
   */
  dimension: any[];
  /**
   * Percentile
   * Percentile estimate that the curve represents
   * @min 0
   * @max 100
   * @default 50
   */
  percentile?: number;
}

/**
 * RiskRatingEnum
 * Enum representing the risk rating levels, with associated scores.
 *
 * Members:
 *     Catastrophic: The highest risk level with a score of 9.
 *     VeryHigh: A very high risk level with a score of 8.
 *     HighVeryHigh: A high to very high risk level with a score of 7.
 *     High: A high risk level with a score of 6.
 *     MediumHigh: A medium to high risk level with a score of 5.
 *     Medium: A medium risk level with a score of 4.
 *     LowMedium: A low to medium risk level with a score of 3.
 *     Low: A low risk level with a score of 2.
 *     VeryLow: A very low risk level with a score of 1.
 *     Negligible: A negligible risk level with a score of 0.
 *     NotAssessed: A risk level that has not been assessed, with a score of -1.
 *     Plausible: A plausible risk level with a score of -2.
 *
 * Properties:
 *     score: The score associated with the risk rating level.
 */
export enum RiskRatingEnum {
  Catastrophic = "Catastrophic",
  VeryHigh = "Very High",
  HighVeryHigh = "High-Very High",
  High = "High",
  MediumHigh = "Medium-High",
  Medium = "Medium",
  LowMedium = "Low-Medium",
  Low = "Low",
  VeryLow = "Very Low",
  Negligible = "Negligible",
  NotAssessed = "Not Assessed",
  Plausible = "Plausible",
}

/** RiskRatingGrouping */
export enum RiskRatingGrouping {
  HAZARD = "HAZARD",
  CONSEQUENCE = "CONSEQUENCE",
}

/**
 * RiskRatingHazardsEnum
 * Enumeration of all possible hazards for use in risk rating assessments.
 */
export enum RiskRatingHazardsEnum {
  GeophysicalSeismic = "geophysical_seismic",
  GeophysicalLiquefaction = "geophysical_liquefaction",
  GeophysicalTsunami = "geophysical_tsunami",
  GeophysicalLandslide = "geophysical_landslide",
  GeophysicalVolcano = "geophysical_volcano",
  GeophysicalAvalanche = "geophysical_avalanche",
  GeophysicalGroundSettlement = "geophysical_ground_settlement",
  HydrologicalFlooding = "hydrological_flooding",
  HydrologicalStormwaterFlooding = "hydrological_stormwater_flooding",
  HydrologicalRiverineFlooding = "hydrological_riverine_flooding",
  HydrologicalCoastalFloodingAndSeaLevelRise = "hydrological_coastal_flooding_and_sea_level_rise",
  ClimatologicalExtremeHeat = "climatological_extreme_heat",
  ClimatologicalDrought = "climatological_drought",
  ClimatologicalWildfire = "climatological_wildfire",
  ClimatologicalWildfireSmoke = "climatological_wildfire_smoke",
  ClimatologicalExtremeCold = "climatological_extreme_cold",
  MeteorologicalIntegratedWind = "meteorological_integrated_wind",
  MeteorologicalCyclonicWind = "meteorological_cyclonic_wind",
  MeteorologicalThunderstormsLocalStorms = "meteorological_thunderstorms_local_storms",
  MeteorologicalExtraTropicalDepressions = "meteorological_extra_tropical_depressions",
  MeteorologicalTornado = "meteorological_tornado",
  MeteorologicalDustStorm = "meteorological_dust_storm",
  MeteorologicalHail = "meteorological_hail",
  MeteorologicalSnow = "meteorological_snow",
  MeteorologicalIceStorm = "meteorological_ice_storm",
  MeteorologicalLightning = "meteorological_lightning",
  AdjacencyAirport = "adjacency_airport",
  AdjacencyHighway = "adjacency_highway",
  AdjacencyRailway = "adjacency_railway",
  AdjacencyPipeline = "adjacency_pipeline",
  AdjacencyIndustrialFacilities = "adjacency_industrial_facilities",
  AdjacencyNuclear = "adjacency_nuclear",
  AdjacencyDams = "adjacency_dams",
  AdjacencyRoads = "adjacency_roads",
  AttributeValues = "attribute_values",
  TechnologicalStructuralIntegrity = "technological_structural_integrity",
  TechnologicalFire = "technological_fire",
  HydrologicalGroundwaterFlooding = "hydrological_groundwater_flooding",
  IntentionalTerrorism = "intentional_terrorism",
  IntentionalCriminality = "intentional_criminality",
  IntentionalStateOnStateMilitaryAttack = "intentional_state_on_state_military_attack",
}

/** RiskRatingRequest */
export interface RiskRatingRequest {
  /** Assessment type. Possible values: CREATED, IN_PROGRESS, RUNNING, PUBLISHED, FAILED, COMPLETED, QA */
  assessment_type: AssessmentTypeEnum;
  /**
   * Rcp Scenario
   * Representative Concentration Pathway. This field is mandatory for FUTURE risk ratings. Possible values : 1.9, 2.6, 3.4, 4.5, 6.0, 7.0, 8.5
   * @default "0"
   */
  rcp_scenario?: RCPScenarioEnum | "0" | null;
  /**
   * Time Horizon
   * Time horizon associated to with the risk rating. Defaults to zero if nothing is provided.
   * @default 0
   */
  time_horizon?: number | null;
  /**
   * Display Economic Loss
   * Flag to show Economic Loss
   * @default false
   */
  display_economic_loss?: boolean | null;
  /**
   * Display Inventory Loss
   * Flag to show Inventory Loss
   * @default false
   */
  display_inventory_loss?: boolean | null;
  /**
   * Display Downtime
   * Flag to show Downtime
   * @default false
   */
  display_downtime?: boolean | null;
  /**
   * Display Life Safety
   * Flag to show Life Safety
   * @default false
   */
  display_life_safety?: boolean | null;
  /**
   * Display Aggregate Life Safety
   * Flag to show Aggregate Life Safety
   * @default false
   */
  display_aggregate_life_safety?: boolean | null;
  /**
   * Display Siteaccess Roads
   * Flag to show Access Roads
   * @default false
   */
  display_siteaccess_roads?: boolean | null;
  /**
   * Display Siteaccess Bridges
   * Flag to show Access Bridges
   * @default false
   */
  display_siteaccess_bridges?: boolean | null;
  /**
   * Display Siteaccess Rail
   * Flag to show Site Access Rail
   * @default false
   */
  display_siteaccess_rail?: boolean | null;
  /**
   * Display Utility Power
   * Flag to show Utility Power
   * @default false
   */
  display_utility_power?: boolean | null;
  /**
   * Display Utility Water
   * Flag to show Utility Water
   * @default false
   */
  display_utility_water?: boolean | null;
  /**
   * Display Utility Waste
   * Flag to show Utility Waste
   * @default false
   */
  display_utility_waste?: boolean | null;
  /**
   * Display Utility Telecom
   * Flag to show Utility Telecom
   * @default false
   */
  display_utility_telecom?: boolean | null;
  /**
   * Display Utility Fiber
   * Flag to show Utility Fiber
   * @default false
   */
  display_utility_fiber?: boolean | null;
  /**
   * Display Health And Wellness
   * Flag to show Health and Wellness
   * @default false
   */
  display_health_and_wellness?: boolean | null;
  /**
   * Display Damage
   * Flag to show Damage
   * @default false
   */
  display_damage?: boolean | null;
  /**
   * Display Severe Weather Warning
   * Flag to show Severe Weather Warning
   * @default false
   */
  display_severe_weather_warning?: boolean | null;
  /**
   * Display Hazard Rating
   * Flag to show Hazard Rating
   * @default false
   */
  display_hazard_rating?: boolean | null;
  /**
   * Name
   * Risk rating name.Length of the name must be shorter than 100 chars.
   */
  name?: string | null;
  /**
   * Executive Summary
   * Executive summary risk rating
   */
  executive_summary?: string | null;
  /**
   * Ratings
   * Dictionary of ratings including extra information, keyed on the hazard/subhazard
   */
  ratings: Record<string, ConsequenceRatingsDictInput>;
  /**
   * Ref Id
   * Asset or Group id to which the risk ratings are defined for.
   */
  ref_id: string;
  /** If the provided ref_id is a ASSET or GROUP */
  ref_type: ReferenceTypeEnum;
  /**
   * Status of the assessment. Possible values: CREATED, IN_PROGRESS, RUNNING, PUBLISHED, FAILED, COMPLETED, QA
   * @default "IN_PROGRESS"
   */
  status?: StatusEnum | null;
}

/** RiskRatingResponse */
export interface RiskRatingResponse {
  /** Assessment type. Possible values: CREATED, IN_PROGRESS, RUNNING, PUBLISHED, FAILED, COMPLETED, QA */
  assessment_type: AssessmentTypeEnum;
  /**
   * Rcp Scenario
   * Representative Concentration Pathway. This field is mandatory for FUTURE risk ratings. Possible values : 1.9, 2.6, 3.4, 4.5, 6.0, 7.0, 8.5
   * @default "0"
   */
  rcp_scenario?: RCPScenarioEnum | "0" | null;
  /**
   * Time Horizon
   * Time horizon associated to with the risk rating. Defaults to zero if nothing is provided.
   * @default 0
   */
  time_horizon?: number | null;
  /**
   * Display Economic Loss
   * Flag to show Economic Loss
   * @default false
   */
  display_economic_loss?: boolean | null;
  /**
   * Display Inventory Loss
   * Flag to show Inventory Loss
   * @default false
   */
  display_inventory_loss?: boolean | null;
  /**
   * Display Downtime
   * Flag to show Downtime
   * @default false
   */
  display_downtime?: boolean | null;
  /**
   * Display Life Safety
   * Flag to show Life Safety
   * @default false
   */
  display_life_safety?: boolean | null;
  /**
   * Display Aggregate Life Safety
   * Flag to show Aggregate Life Safety
   * @default false
   */
  display_aggregate_life_safety?: boolean | null;
  /**
   * Display Siteaccess Roads
   * Flag to show Access Roads
   * @default false
   */
  display_siteaccess_roads?: boolean | null;
  /**
   * Display Siteaccess Bridges
   * Flag to show Access Bridges
   * @default false
   */
  display_siteaccess_bridges?: boolean | null;
  /**
   * Display Siteaccess Rail
   * Flag to show Site Access Rail
   * @default false
   */
  display_siteaccess_rail?: boolean | null;
  /**
   * Display Utility Power
   * Flag to show Utility Power
   * @default false
   */
  display_utility_power?: boolean | null;
  /**
   * Display Utility Water
   * Flag to show Utility Water
   * @default false
   */
  display_utility_water?: boolean | null;
  /**
   * Display Utility Waste
   * Flag to show Utility Waste
   * @default false
   */
  display_utility_waste?: boolean | null;
  /**
   * Display Utility Telecom
   * Flag to show Utility Telecom
   * @default false
   */
  display_utility_telecom?: boolean | null;
  /**
   * Display Utility Fiber
   * Flag to show Utility Fiber
   * @default false
   */
  display_utility_fiber?: boolean | null;
  /**
   * Display Health And Wellness
   * Flag to show Health and Wellness
   * @default false
   */
  display_health_and_wellness?: boolean | null;
  /**
   * Display Damage
   * Flag to show Damage
   * @default false
   */
  display_damage?: boolean | null;
  /**
   * Display Severe Weather Warning
   * Flag to show Severe Weather Warning
   * @default false
   */
  display_severe_weather_warning?: boolean | null;
  /**
   * Display Hazard Rating
   * Flag to show Hazard Rating
   * @default false
   */
  display_hazard_rating?: boolean | null;
  /**
   * Name
   * Risk rating name.Length of the name must be shorter than 100 chars.
   */
  name?: string | null;
  /**
   * Executive Summary
   * Executive summary risk rating
   */
  executive_summary?: string | null;
  /**
   * Ratings
   * Dictionary of ratings including extra information, keyed on the hazard/subhazard
   */
  ratings: Record<string, ConsequenceRatingsDictOutput>;
  /**
   * Ref Id
   * Asset or Group id to which the risk ratings are defined for.
   */
  ref_id: string;
  /** If the provided ref_id is a ASSET or GROUP */
  ref_type: ReferenceTypeEnum;
  /**
   * Status of the assessment. Possible values: CREATED, IN_PROGRESS, RUNNING, PUBLISHED, FAILED, COMPLETED, QA
   * @default "IN_PROGRESS"
   */
  status?: StatusEnum | null;
  /**
   * Id
   * Main key to identify Risk Rating
   */
  id: string;
  /**
   * Version
   * version number
   */
  version: number;
  /**
   * Created At
   * When the file was uploaded.
   * @format date-time
   */
  created_at: string;
  /**
   * Created By
   * User who created the risk rating.
   */
  created_by: string;
  /**
   * Updated At
   * When the risk ratings were updated [UTC].
   */
  updated_at?: string | null;
  /**
   * Updated By
   * User who updated the risk ratings.
   */
  updated_by?: string | null;
  /**
   * Status Updated At
   * When the assessment status was updated last time
   */
  status_updated_at?: string | null;
  /**
   * Status Updated By
   * Who updated the assessment last time
   */
  status_updated_by?: string | null;
  /**
   * Archived
   * If a risk rating is archived or not.
   * @default false
   */
  archived?: boolean | null;
}

/** RoadAdjacencyRiskRequestApi */
export interface RoadAdjacencyRiskRequestApi {
  /**
   * Distance
   * Shortest distance from site to railroad centerline.
   */
  distance: number;
  /**
   * Site Segment Length
   * Site segment length.
   */
  site_segment_length: number;
}

/** Scope */
export enum Scope {
  GLOBAL = "GLOBAL",
  ORGANIZATION = "ORGANIZATION",
}

/** SeismicComponent */
export interface SeismicComponent {
  /**
   * Nistr
   * Identification tag for the component, as defined by FEMA P-58
   */
  NISTR: string;
  /**
   * Region
   * Region for this component.
   * @default "USA"
   */
  region?: string | null;
  /** Risk Engine */
  risk_engine: "SEISMIC";
  /**
   * Nickname
   * Nickname for this version of the given component.
   */
  nickname?: string | null;
  /**
   * Repair sequence
   * Repair sequence for the component
   */
  seq: number[];
  /**
   * Number of damage states
   * Number of damage states
   */
  n_ds: number;
  /**
   * Long lead time
   * Long lead time for each damage state
   */
  long_lead: number[];
  /**
   * Fragility median
   * Median values of the fragility curves
   */
  theta_ds: number[][];
  /**
   * Fragility uncertainty
   * Beta values of the fragility curves
   */
  beta_ds: number[][];
  /**
   * Repair cost distribution
   * Distribution type for the repair cost curves
   */
  cds_fit: string[][];
  /**
   * Repair cost median
   * Median values for the repair cost curves
   */
  cds_mean: number[][][];
  /**
   * Repair cost uncertainty
   * Beta values of the repair cost curves
   */
  cds_beta: number[][];
  /**
   * Repair class
   * Repair class for each damage state
   */
  rds: number[];
  /**
   * Repair time distribution
   * Distribution type for the repair time curves
   */
  rds_fit: string[][];
  /**
   * Repair time median
   * Median values for the repair time curves
   */
  rds_mean: number[][][];
  /**
   * Repair time uncertainty
   * Beta values of the repair time curves
   */
  rds_beta: number[][];
  /**
   * Component
   * component
   */
  component: string;
  /**
   * Subgroup
   * subgroup
   */
  subgroup: string;
  /**
   * Compdesc
   * comment description
   */
  compdesc: string;
  /**
   * Parameter
   * EDP associated with the fragility curves for this component
   */
  param: string;
  /**
   * Parameter units
   * Units associated with the 'Parameter'
   */
  param_units: string;
  /**
   * Normative quantities
   * Average quantities associated with different occupancy types
   */
  q_norm: number[][];
  /**
   * Demand location
   * Location of the demand (0 for floor, 1 for ceiling)
   */
  demand_loc: number;
  /**
   * Repair cost cutoffs
   * Cutoff values associated with the median values for the repair cost curves
   */
  cds_cutoff: number[][][];
  /**
   * Repair time cutoffs
   * Cutoff values associated with the median values for the repair time curves
   */
  rds_cutoff: number[][][];
  /**
   * Injury median
   * Median values for the injury curves
   */
  ids_median: number[][];
  /**
   * Injury uncertainty
   * Beta values for the injury curves
   */
  ids_beta: number[][];
  /**
   * Fatality median
   * Median values for the fatality curves
   */
  dds_median: number[][];
  /**
   * Fatality uncertainty
   * Beta values for the fatality curves
   */
  dds_beta: number[][];
  /**
   * Affected area
   * Affected area for each damage state
   */
  affected_area: number[][];
  /**
   * Long lead flag
   * Booolean indicator for whether or not the damage state has a long lead time
   */
  long_lead_flag: boolean[] | null;
  /**
   * Probability of damage state
   * Probably of the damage state occurring. This is only relevant for mutually-exclusive damage states, which are rare
   */
  pds: number[][];
  /**
   * Correlation coefficient
   * Correlation coefficient of component damage state with other components on same floor
   */
  correlated: number;
  /**
   * Comments
   * comments
   */
  comments: string;
  /**
   * Description
   * description
   */
  description: string;
  /**
   * Custom
   * custom
   * @default false
   */
  custom?: boolean | null;
  /**
   * Database Access
   * Database access
   */
  database_access: string[];
  /**
   * Dataquality
   * data quality
   */
  dataquality: string;
  /**
   * Datarelevance
   * data relevance
   */
  datarelevance: string;
  /**
   * Directional
   * directional
   */
  directional?: boolean | null;
  /**
   * Docquality
   * docquality
   */
  docquality: string;
  /**
   * Dsdesc
   * Database access
   */
  DSdesc: string[][];
  /**
   * Group
   * group
   */
  group: string;
  /**
   * Hazards
   * hazards
   */
  hazards: string[];
  /**
   * Heir
   * heir
   */
  heir: string;
  /**
   * Rationality
   * rationality
   */
  rationality: string;
  /**
   * Repairdesc
   * repairdesc
   */
  repairdesc: string[][];
  /**
   * Tpr
   * tpr
   */
  tpr: number;
}

/** SeismicEDPsHazard */
export interface SeismicEDPsHazard {
  /** Distribution from which to sample. Allowed values Simple, Normal, Lognormal, Custom,EDP */
  im_distribution: HazardDistribution;
  /**
   * Ground Acc X
   * A vector of ground accelerations per realization. Each item in the list represents the X component ground acceleration at a particular floor, starting from the ground floor. Note all the ground accelleration values are the same at every floor. (Units: Fraction of gravitational acceleration (g))
   */
  ground_acc_x: number[][];
  /**
   * Ground Acc Y
   * A vector of ground accelerations per realization. Each item in the list represents the Y component ground acceleration at a particular floor, starting from the ground floor. Note all the ground accelleration values are the same at every floor. (Units: Fraction of gravitational acceleration (g))
   */
  ground_acc_y: number[][];
  /**
   * Acc X
   * A vector of floor accelerations per realization. Each item in the list represents the X component acceleration at a particular floor, starting from the ground floor (Units: Fraction of gravitational acceleration (g))
   */
  acc_x: number[][];
  /**
   * Acc Y
   * A vector of floor accelerations per realization. Each item in the list represents the Y component acceleration at a particular floor, starting from the ground floor (Units: Fraction of gravitational acceleration (g))
   */
  acc_y: number[][];
  /**
   * Drift X
   * A vector of drifts per realization. Each item in the list represents the X drift at a particular floor, starting from the ground floor (Units: ratio of displacement over story height)
   */
  drift_x: number[][];
  /**
   * Drift Y
   * A vector of drifts per realization. Each item in the list represents the Y drift at a particular floor, starting from the ground floor (Units: ratio of displacement over story height)
   */
  drift_y: number[][];
  /**
   * Res Drift X
   * A vector of residual drifts per realization. Each item in the list represents the X residual drift at a particular floor, starting from the ground floor (Units: ratio of displacement over story height)
   */
  res_drift_x: number[][];
  /**
   * Res Drift Y
   * A vector of residual drifts per realization. Each item in the list represents the Y residual drift at a particular floor, starting from the ground floor (Units: ratio of displacement over story height)
   */
  res_drift_y: number[][];
  /**
   * Collapse
   * Vector reporting the building collapse extent per realization.
   */
  collapse: number[];
}

/** SeismicHazardCustomDistribution */
export interface SeismicHazardCustomDistribution {
  /** Distribution from which to sample. Allowed values Simple, Normal, Lognormal, Custom,EDP */
  im_distribution: HazardDistribution;
  /**
   * Sat
   * A custom vector of spectral accelerations for each realization. Each item in the list represents the X and Y components of the acceleration (Units: Fraction of gravitational acceleration (g))
   */
  SaT: number[][];
  /**
   * Pga
   * A custom vector of peak ground accelerations for each realization. Each item in the list represents the X and Y components of the ground acceleration. (Units: Fraction of gravitational acceleration (g))
   */
  PGA: number[][];
}

/** SeismicHazardStandardDistribution */
export interface SeismicHazardStandardDistribution {
  /** Distribution from which to sample. Allowed values Simple, Normal, Lognormal, Custom,EDP */
  im_distribution: HazardDistribution;
  /**
   * Sa Theta
   * Median spectral acceleration at the period of the building (Units: Fraction of gravitational acceleration (g))
   * @maxItems 2
   * @minItems 2
   */
  sa_theta: number[];
  /**
   * Pga Theta
   * Median peak ground acceleration (Units: Fraction of gravitational acceleration (g))
   * @maxItems 2
   * @minItems 2
   */
  pga_theta: number[];
  /**
   * Sa Beta
   * Uncertainty on spectral acceleration (e.g. uncertainty for what exactly defines '100-year shaking')
   * @min 0
   */
  sa_beta: number;
  /**
   * Pga Beta
   * Uncertainty on peak ground acceleration (e.g. uncertainty for what exactly defines '100-year shaking')
   */
  pga_beta: number;
}

/** ServiceReferenceTypeEnum */
export enum ServiceReferenceTypeEnum {
  HAZARD = "HAZARD",
  RISK_RATING = "RISK_RATING",
  ASSESSMENT = "ASSESSMENT",
  LIBRARY = "LIBRARY",
}

/** ShapeType */
export enum ShapeType {
  POINT = "POINT",
  AREA = "AREA",
}

/** SimpleHazardDistribution */
export interface SimpleHazardDistributionInput {
  /** Distribution from which to sample. Allowed values Simple, Normal, Lognormal, Custom,EDP */
  im_distribution: HazardDistribution;
  /** The hazard curve */
  theta: HazardCurveInput;
  /** The uncertainty curve for the hazard curve */
  beta?: HazardCurveInput | null;
}

/** SimpleHazardDistribution */
export interface SimpleHazardDistributionOutput {
  /** Distribution from which to sample. Allowed values Simple, Normal, Lognormal, Custom,EDP */
  im_distribution: HazardDistribution;
  /** The hazard curve */
  theta: HazardCurveOutput;
  /** The uncertainty curve for the hazard curve */
  beta?: HazardCurveOutput | null;
}

/** SimpleJobResponse */
export interface SimpleJobResponse {
  /**
   * Id
   * The unique identifier of the job.
   */
  id: string;
  /**
   * Details
   * Message explaining more about the job, e.g. error message if it failed.
   */
  details: string;
}

/** Specification */
export type Specification = object;

/** StatusEnum */
export enum StatusEnum {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  RUNNING = "RUNNING",
  PUBLISHED = "PUBLISHED",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  QA = "QA",
}

/** SubHazard */
export interface SubHazard {
  /** The SubHazard type */
  sub_hazard: SubHazardType;
  /** The Hazard type */
  hazard_type?: HazardType | null;
  /** The Hazard category */
  hazard_category: HazardCategory;
  /**
   * Risk Class Engines
   * List of risk engines available for the sub-hazard. If not provided, no risk engines are available.
   */
  risk_class_engines: RiskClassEnum[] | null;
  /**
   * Intensity Measures
   * List of intensity measures available for the sub-hazard. If not provided, no intensity measures are available.
   */
  intensity_measures?: IntensityMeasure[] | null;
  /**
   * Consequences
   * List of consequences available for the subhazard.
   */
  consequences?: Consequence[];
}

/** SubHazardBase */
export interface SubHazardBase {
  /** The SubHazard type */
  sub_hazard: SubHazardType;
  /** The Hazard type */
  hazard_type?: HazardType | null;
  /** The Hazard category */
  hazard_category: HazardCategory;
  /**
   * Risk Class Engines
   * List of risk engines available for the sub-hazard. If not provided, no risk engines are available.
   */
  risk_class_engines: RiskClassEnum[] | null;
}

/** SubHazardType */
export enum SubHazardType {
  EARTHQUAKE_SHAKING = "EARTHQUAKE_SHAKING",
  INTEGRATED_FLOODING = "INTEGRATED_FLOODING",
  RIVERINE_FLOODING = "RIVERINE_FLOODING",
  COASTAL_FLOODING = "COASTAL_FLOODING",
  STORMWATER_FLOODING = "STORMWATER_FLOODING",
  GROUNDWATER_FLOODING = "GROUNDWATER_FLOODING",
  INTEGRATED_WIND = "INTEGRATED_WIND",
  CYCLONIC_WIND = "CYCLONIC_WIND",
  WILDFIRE = "WILDFIRE",
  TORNADO = "TORNADO",
  TSUNAMI = "TSUNAMI",
  LANDSLIDE = "LANDSLIDE",
  VOLCANO = "VOLCANO",
  AVALANCHE = "AVALANCHE",
  HIGHWAY = "HIGHWAY",
  RAILWAY = "RAILWAY",
  AIRPORT = "AIRPORT",
  NUCLEAR = "NUCLEAR",
  PIPELINE = "PIPELINE",
  LIGHTNING = "LIGHTNING",
  LIQUEFACTION = "LIQUEFACTION",
  GROUND_SETTLEMENT = "GROUND_SETTLEMENT",
  DAMS = "DAMS",
  DROUGHT = "DROUGHT",
  WILDFIRE_SMOKE = "WILDFIRE_SMOKE",
  HEAT = "HEAT",
  COLD = "COLD",
  HAIL = "HAIL",
  DUST_STORM = "DUST_STORM",
  SNOW = "SNOW",
  ICE_STORM = "ICE_STORM",
  INDUSTRIAL_FACILITY = "INDUSTRIAL_FACILITY",
  THUNDERSTORM = "THUNDERSTORM",
  TERRORISM = "TERRORISM",
  CRIMINALITY = "CRIMINALITY",
  STATE_ON_STATE_MILITARY_ATTACK = "STATE_ON_STATE_MILITARY_ATTACK",
  ROAD_LPG_BLEVE = "ROAD_LPG_BLEVE",
  ROAD_FIRE = "ROAD_FIRE",
  ROAD_TOXIC_GAS_RELEASE = "ROAD_TOXIC_GAS_RELEASE",
  ROAD_DERAILMENT = "ROAD_DERAILMENT",
  RAIL_LPG_BLEVE = "RAIL_LPG_BLEVE",
  RAIL_FIRE = "RAIL_FIRE",
  RAIL_TOXIC_GAS_RELEASE = "RAIL_TOXIC_GAS_RELEASE",
  RAIL_DERAILMENT = "RAIL_DERAILMENT",
  AIRPORT_ADJACENCY = "AIRPORT_ADJACENCY",
}

/** TerrainCategory */
export enum TerrainCategory {
  URBAN = "URBAN",
  OPEN_TERRAIN = "OPEN_TERRAIN",
  UNOBSTRUCTED = "UNOBSTRUCTED",
}

/** UnitType */
export enum UnitType {
  Ft = "ft",
  Miles = "miles",
  M = "m",
  Cm = "cm",
  Mm = "mm",
  G = "g",
  Mph = "mph",
  CmS = "cm / s",
  MS = "m / s",
  Lb = "lb",
  Kg = "kg",
  Count = "count",
  Percent = "percent",
  Percentage = "percentage",
  Dimensionless = "dimensionless",
  DegF = "degF",
  DegC = "degC",
  DegreeFahrenheit = "degree_Fahrenheit",
  DegreeCelsius = "degree_Celsius",
  K = "K",
  DeltaDegC = "delta_degC",
  DeltaDegF = "delta_degF",
  DeltaDegreeCelsius = "delta_degree_Celsius",
  DeltaDegreeFahrenheit = "delta_degree_Fahrenheit",
  Year = "year",
  Month = "month",
  Day = "day",
  Second = "second",
  Minute = "minute",
  Days = "days",
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  AUD = "AUD",
}

/** UpdateAssessmentComponentRequest */
export interface UpdateAssessmentComponentRequest {
  /**
   * Floor
   * The floor the component is on
   */
  floor?: number | null;
  /**
   * Nistr
   * Identification tag for the component
   */
  NISTR?: string | null;
  /**
   * Qty
   * X and Y Quantity
   */
  qty?: number[] | null;
  /**
   * Elev
   * Elevation values for this component on the given floor
   */
  elev?: number[] | null;
}

/** UpdateAssessmentOutputRequest */
export interface UpdateAssessmentOutputRequest {
  /**
   * Value
   * The updated value of the output
   */
  value:
    | number
    | string
    | any[][]
    | (
        | ({
            curve_type: "ARCHETYPE_LOSS";
          } & ArchetypeLossCurveInput)
        | ({
            curve_type: "BASE";
          } & BaseCurveInput)
        | ({
            curve_type: "HAZARD";
          } & HazardCurveInput)
        | ({
            curve_type: "LOSS";
          } & LossCurveInput)
        | ({
            curve_type: "RISK_RATING";
          } & RiskRatingCurveInput)
      );
}

/** UpdateResourceRequest */
export interface UpdateResourceRequest {
  /**
   * Metadata
   * Meta data about the file
   */
  metadata?: object | null;
  /** The type of resource that the file represents */
  resource_type?: ResourceTypeEnum | null;
}

/** UpdateRiskParametersRequest */
export interface UpdateRiskParametersRequest {
  general?: AppModelsRiskParametersRiskParametersGeneral | null;
  repair?: AppModelsRiskParametersRiskParametersRepair | null;
  wind?: AppModelsRiskParametersRiskParametersWind | null;
  impeding_factors?: AppModelsRiskParametersRiskParametersImpedingFactors | null;
  contents?: AppModelsRiskParametersRiskParametersContents | null;
  seismic?: AppModelsRiskParametersRiskParametersSeismic | null;
  business?: AppModelsRiskParametersRiskParametersBusiness | null;
}

/** UpdateRiskParametersSharedFateRequest */
export interface UpdateRiskParametersSharedFateRequest {
  repair?: AppModelsRiskParametersRiskParametersSharedFateRepair | null;
  wind?: AppModelsRiskParametersRiskParametersSharedFateWind | null;
  general?: AppModelsRiskParametersRiskParametersSharedFateGeneral | null;
  impeding_factors?: AppModelsRiskParametersRiskParametersSharedFateImpedingFactors | null;
  contents?: AppModelsRiskParametersRiskParametersSharedFateContents | null;
  seismic?: AppModelsRiskParametersRiskParametersSharedFateSeismic | null;
  business?: AppModelsRiskParametersRiskParametersSharedFateBusiness | null;
}

/** UpdateRiskRatingRequest */
export interface UpdateRiskRatingRequest {
  /**
   * Display Economic Loss
   * Flag to show Economic Loss
   */
  display_economic_loss?: boolean | null;
  /**
   * Display Inventory Loss
   * Flag to show Inventory Loss
   */
  display_inventory_loss?: boolean | null;
  /**
   * Display Downtime
   * Flag to show Downtime
   */
  display_downtime?: boolean | null;
  /**
   * Display Life Safety
   * Flag to show Life Safety
   */
  display_life_safety?: boolean | null;
  /**
   * Display Aggregate Life Safety
   * Flag to show Aggregate Life Safety
   */
  display_aggregate_life_safety?: boolean | null;
  /**
   * Display Siteaccess Roads
   * Flag to show Access Roads
   */
  display_siteaccess_roads?: boolean | null;
  /**
   * Display Siteaccess Bridges
   * Flag to show Access Bridges
   */
  display_siteaccess_bridges?: boolean | null;
  /**
   * Display Siteaccess Rail
   * Flag to show Site Access Rail
   */
  display_siteaccess_rail?: boolean | null;
  /**
   * Display Utility Power
   * Flag to show Utility Power
   */
  display_utility_power?: boolean | null;
  /**
   * Display Utility Water
   * Flag to show Utility Water
   */
  display_utility_water?: boolean | null;
  /**
   * Display Utility Waste
   * Flag to show Utility Waste
   */
  display_utility_waste?: boolean | null;
  /**
   * Display Utility Telecom
   * Flag to show Utility Telecom
   */
  display_utility_telecom?: boolean | null;
  /**
   * Display Utility Fiber
   * Flag to show Utility Fiber
   */
  display_utility_fiber?: boolean | null;
  /**
   * Display Health And Wellness
   * Flag to show Health and Wellness
   */
  display_health_and_wellness?: boolean | null;
  /**
   * Display Damage
   * Flag to show Damage
   */
  display_damage?: boolean | null;
  /**
   * Display Severe Weather Warning
   * Flag to show Severe Weather Warning
   */
  display_severe_weather_warning?: boolean | null;
  /**
   * Display Hazard Rating
   * Flag to show Hazard Rating
   */
  display_hazard_rating?: boolean | null;
  /**
   * Name
   * Risk rating name.Length of the name must be shorter than 100 chars.
   */
  name?: string | null;
  /**
   * Executive Summary
   * Executive summary risk rating
   */
  executive_summary?: string | null;
  /**
   * Ratings
   * Dictionary of ratings including extra information, keyed on the hazard/subhazard
   */
  ratings?: Record<string, ConsequenceRatingsDictInput> | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** WindComponent */
export interface WindComponent {
  /**
   * Nistr
   * Identification tag for the component, as defined by FEMA P-58
   */
  NISTR: string;
  /**
   * Region
   * Region for this component.
   * @default "USA"
   */
  region?: string | null;
  /** Risk Engine */
  risk_engine: "WIND";
  /**
   * Nickname
   * Nickname for this version of the given component.
   */
  nickname?: string | null;
  /**
   * Repair sequence
   * Repair sequence for the component
   */
  seq: number[];
  /**
   * Number of damage states
   * Number of damage states
   */
  n_ds: number;
  /**
   * Long lead time
   * Long lead time for each damage state
   */
  long_lead: number[];
  /**
   * Fragility median
   * Median values of the fragility curves
   */
  theta_ds: number[][];
  /**
   * Fragility uncertainty
   * Beta values of the fragility curves
   */
  beta_ds: number[][];
  /**
   * Repair cost distribution
   * Distribution type for the repair cost curves
   */
  cds_fit: string[][];
  /**
   * Repair cost median
   * Median values for the repair cost curves
   */
  cds_mean: number[][][];
  /**
   * Repair cost uncertainty
   * Beta values of the repair cost curves
   */
  cds_beta: number[][];
  /**
   * Repair class
   * Repair class for each damage state
   */
  rds: number[];
  /**
   * Repair time distribution
   * Distribution type for the repair time curves
   */
  rds_fit: string[][];
  /**
   * Repair time median
   * Median values for the repair time curves
   */
  rds_mean: number[][][];
  /**
   * Repair time uncertainty
   * Beta values of the repair time curves
   */
  rds_beta: number[][];
  /**
   * Component
   * component
   */
  component: string;
  /**
   * Subgroup
   * subgroup
   */
  subgroup: string;
  /**
   * Compdesc
   * comment description
   */
  compdesc: string;
  /**
   * Comp Location
   * The component location
   */
  comp_location: string;
  /**
   * Breach Area
   * The area that is breached in sqft
   */
  breach_area?: number | null;
  /**
   * Length
   * The component length in ft
   */
  length?: number | null;
  /**
   * Width
   * The component width in ft
   */
  width?: number | null;
  /**
   * Height
   * The component height in ft
   */
  height?: number | null;
  /**
   * Weight
   * The component weight in lbs
   */
  weight?: number | null;
  /**
   * Cap Mean
   *
   *             The mean of the component capacity.
   *             Roof cover: rating (unit: psf).
   *             Roof joist: connection uplift capacity (unit: lbs).
   *             Rooftop / ground equipment: panel capacity (unit: psf).
   *
   */
  cap_mean?: number | null;
  /**
   * Cap Cov
   *
   *             The coefficient of variance of the component capacity.
   *             Roof cover: rating.
   *             Roof joist: connection uplift capacity.
   *             Rooftop / ground equipment: panel capacity.
   *
   */
  cap_cov?: number | null;
  /**
   * Buckling Pressure Mean
   * The mean of the buckling pressure (for roof joists) in psf
   */
  buckling_pressure_mean?: number | null;
  /**
   * Buckling Pressure Cov
   * The coefficient of variance of the buckling pressure (for roof joists)
   */
  buckling_pressure_cov?: number | null;
  /**
   * Anchor Num
   * The number of anchors per equipment
   */
  anchor_num?: number | null;
  /**
   * Anchor Strength
   * The mean strenght of the anchor in kips
   */
  anchor_strength?: number | null;
  /**
   * Anchor Strength Cov
   * The coefficient of variance of the strenght of the anchor
   */
  anchor_strength_cov?: number | null;
  /** The quality of anchor installation. Options: Good, Fair, Poor, Very Poor */
  anchor_quality?: AnchorQualityEnum | null;
  /**
   * Joist Spacing
   * The distance between roof joists in ft
   */
  joist_spacing?: number | null;
  /**
   * Joist Span Num
   * The total number of roof joist spans
   */
  joist_span_num?: number | null;
  /**
   * Comp Type
   * The component type
   */
  comp_type: string;
  /**
   * Allowed On Roof
   * Whether a component can be placed on the roof or not
   * @default true
   */
  allowed_on_roof?: boolean;
}

/** WindHazardCustomDistribution */
export interface WindHazardCustomDistribution {
  /** Distribution from which to sample. Allowed values Simple, Normal, Lognormal, Custom,EDP */
  im_distribution: HazardDistribution;
  /**
   * Max Wind Direction
   * Max Wind Direction [degrees]
   */
  max_wind_direction: number[];
  /**
   * Hr24 Rainfall
   * 24 Hour Rainfall [inches/day]
   */
  hr24_rainfall: number[];
  /**
   * Max 3S Gust
   * Max 3s Gust [mph]
   */
  max_3s_gust: number[];
}

/** WindHazardRealizations */
export interface WindHazardRealizations {
  /**
   * Max Wind Direction
   * Max Wind Direction [degrees]
   */
  max_wind_direction: number[];
  /**
   * Hr24 Rainfall
   * 24 Hour Rainfall [inches/day]
   */
  hr24_rainfall: number[];
  /**
   * Max 3S Gust
   * Max 3s Gust [mph]
   */
  max_3s_gust: number[];
}

/** WindHazardStandardDistribution */
export interface WindHazardStandardDistribution {
  /**
   * Windspeed Distribution
   * Distribution from which to sample wind speed
   */
  windspeed_distribution: string;
  /**
   * Rainfall Distribution
   * Distribution from which to sample rainfall
   */
  rainfall_distribution: string;
  /**
   * Windspeed Theta
   * Median 3-s gust speed (Units: Miles Per Hour)
   * @min 0
   */
  windspeed_theta: number;
  /**
   * Windspeed Beta
   * Uncertainty on wind speed (e.g. uncertainty on what exactly defines '100-year wind')
   */
  windspeed_beta: number;
  /**
   * Wind Direction
   * Wind direction (relative to North) (Units: Degrees)
   * @min 0
   */
  wind_direction: number;
  /**
   * Rainfall Theta
   * Median 24-hr rainfall (Units: Inches)
   * @min 0
   */
  rainfall_theta: number;
  /**
   * Rainfall Beta
   * Uncertainty on 24-hr rainfall (e.g. uncertainty on the coincident rainfall, given the wind speed)
   * @min 0
   */
  rainfall_beta: number;
}

/** Business */
export interface AppModelsRiskParametersRiskParametersBusiness {
  /**
   * Essential Flag
   * True/False indicator for whether or not building is essential. This will impact downtime
   */
  essential_flag: boolean;
  /**
   * Finance Method
   * Description of finance method
   */
  finance_method: FinanceMethodEnum[];
  /**
   * Insur Limit Ratio
   * Insurance limit (as fraction of replacement cost)
   */
  insur_limit_ratio: number;
  /**
   * Loss Thresh Ratio
   * Loss threshold (as fraction of replacement cost) at which it makes financial sense to redesign building
   */
  loss_thresh_ratio: number;
  /**
   * Available Fund Ratio
   * Available funds (as fraction of replacement cost) for immediate repairs
   */
  available_fund_ratio: number;
  /**
   * Deductible Ratio
   * Deductible (as fraction of replacement cost)
   */
  deductible_ratio: number;
}

/** Contents */
export interface AppModelsRiskParametersRiskParametersContents {
  /**
   * Hazmat Flag
   * True/False indicator for whether or not building is hazardous materials. This will impact repair cost
   */
  hazmat_flag: boolean;
}

/** General */
export interface AppModelsRiskParametersRiskParametersGeneral {
  /**
   * Beta M
   * Uncertainty in modeling EDPs for earthquake shaking
   * @default 0.5
   */
  beta_m?: number | null;
  /**
   * Time Fact
   * Multiplier for financial losses based on year
   */
  time_fact?: number | null;
  /**
   * Loc Fact
   * Multiplier for financial losses based on location
   */
  loc_fact?: number | null;
  /**
   * Replacement Cost
   * Cost (USD) to replace the building if it is a total loss
   * @default 0
   */
  replacement_cost?: number | null;
  /**
   * N Floors
   * Total number of stories, including above and below ground
   * @default 0
   */
  n_floors?: number | null;
  /**
   * Footprint
   * Area of ground floor [sq ft]
   * @default 0
   */
  footprint?: number | null;
  /**
   * Ffe
   * Finished floor elevation of first floor relative to elevation datum for flood depth
   * @default 0
   */
  ffe?: number | null;
  /** Terrain exposure category. */
  terrain_category?: TerrainCategory | null;
  /**
   * Wind Speed Adjustment Factor
   * Wind speed adjustment factor. According to ASCE 7. This factor depends on the number of stories in the building and the terrain exposure category.
   */
  wind_speed_adjustment_factor?: number | null;
}

/** ImpedingFactors */
export interface AppModelsRiskParametersRiskParametersImpedingFactors {
  /**
   * Evacuation Flag
   * True/False indicator for whether or not occupants will evacuate the building in advance of event (e.g. hurricane). This will impact downtime
   */
  evacuation_flag: boolean;
  /**
   * Longlead
   * Distribution (type and COV) for long lead time
   */
  longlead: object;
  /**
   * Inspection Delay
   * Inspection impeding delay
   */
  inspection_delay: object;
  /**
   * Waters Recede Delay
   * Waters recede impeding delay
   */
  waters_recede_delay: object;
  /**
   * Engineer Mobilization Delay Seismic
   * Engineer mobilization impeding delay for seismic
   */
  engineer_mobilization_delay_seismic: object;
  /**
   * Engineer Mobilization Delay Wind
   * Engineer mobilization impeding delay for wind
   */
  engineer_mobilization_delay_wind: object;
  /**
   * Engineer Mobilization Delay Flood
   * Engineer mobilization impeding delay for flood
   */
  engineer_mobilization_delay_flood: object;
  /**
   * Contractor Mobilization Delay Seismic
   * Contractor mobilization impeding delay for seismic
   */
  contractor_mobilization_delay_seismic: object;
  /**
   * Contractor Mobilization Delay Wind
   * Contractor mobilization impeding delay for wind
   */
  contractor_mobilization_delay_wind: object;
  /**
   * Contractor Mobilization Delay Flood
   * Contractor mobilization impeding delay for flood
   */
  contractor_mobilization_delay_flood: object;
  /**
   * Financing Delay
   * Financing impeding delay
   */
  financing_delay: object;
  /**
   * Restoration Delay
   * Restoration impeding delay
   */
  restoration_delay: object;
  /**
   * Permit Delay Seismic
   * Permit impeding delay for seismic
   */
  permit_delay_seismic: object;
  /**
   * Permit Delay Wind
   * Permit impeding delay for wind
   */
  permit_delay_wind: object;
  /**
   * Permit Delay Flood
   * Permit impeding delay for flood
   */
  permit_delay_flood: object;
}

/** Repair */
export interface AppModelsRiskParametersRiskParametersRepair {
  /**
   * Repair Class Fragility
   * Fragility for repair class
   */
  repair_class_fragility: object;
  /**
   * Max Workers By Sequence
   * Maximum number of workers for each repair sequence
   */
  max_workers_by_sequence: number[][];
  /**
   * Max Workers Per Building
   * Function that describes the maximum number of workers that can be in the building, depending on the total square footage
   */
  max_workers_per_building: object;
  /**
   * Nwork Perfloor Divider
   * Number of workers per floor divider for repair sequences A and G
   */
  nwork_perfloor_divider: number[];
  /**
   * Max Workers Per Struct Divider
   * Maximum number of workers per structural divider
   */
  max_workers_per_struct_divider: number;
  /**
   * Nworkers Recommended Mean
   * Mean number of workers per component for repair sequences B, C, D, E, and F
   */
  nworkers_recommended_mean: number[];
  /**
   * Nworkers Recomended Mean Struct
   * Mean number of workers per structural comopnent
   */
  nworkers_recomended_mean_struct: number;
  /**
   * Workers Capacity
   * Distribution (type and COV) for worker capacity
   */
  workers_capacity: object;
  /**
   * N Sequences
   * Total number of repair sequences
   */
  n_sequences: number;
  /**
   * N Nonstruc Sequences
   * Total number of non-structural repair sequences
   */
  n_nonstruc_sequences: number;
  /**
   * N Repair Goals
   * Total number of repair goals
   */
  n_repair_goals: number;
}

/** Seismic */
export interface AppModelsRiskParametersRiskParametersSeismic {
  /**
   * Primary Type
   * Name of the primary lateral force-resisting type
   */
  primary_type: string;
  /**
   * Secondary Type
   * Name of the secondary of orthogonal lateral force-resisting system
   */
  secondary_type: string;
  /**
   * Fema Score
   * Score from FEMA P-154
   */
  fema_score: number;
  /**
   * Fema Code
   * FEMA P-154 benchmark code status
   */
  fema_code: string[];
  /**
   * Fema Year
   * Year used for FEMA P-154 benchmark designation
   */
  fema_year: number;
  /**
   * Sdc
   * Seismic design category
   */
  sdc: string;
  /**
   * Liquefaction
   * Liquefaction
   */
  liquefaction: string;
  /**
   * Site Class
   * Soil site class
   */
  site_class: string;
  /**
   * Norm Strength
   * Normalized strength of lateral system in x- and y-directions
   */
  norm_strength: number[];
  /**
   * T
   * Fundamental structural period of the building
   */
  T: number;
  /**
   * Yield Drift
   * Interstory drift at which yield occurs in the x- and y-directions
   */
  yield_drift: number[];
  /**
   * Building Brittle
   * Boolean (True / False) that identifies if the building is brittle or not
   */
  building_brittle: boolean;
  /**
   * Collapse Fragility
   * This contains theta and beta that defines a lognormal collapse fragility
   */
  collapse_fragility: object;
  /**
   * Torsion Factor
   * Factor to amplify EDPs due to torsional irregularities
   */
  torsion_factor: number;
  /**
   * Collapse Extent
   * Collapse extent. Value bounded between 0 and 1.
   */
  collapse_extent?: number | null;
}

/** Wind */
export interface AppModelsRiskParametersRiskParametersWind {
  /**
   * Design Speed
   * Wind speed (3-s gust) that the building was designed for
   */
  design_speed: number;
  /**
   * Load Factor
   * Load factor considered in wind design
   */
  load_factor: number;
  /**
   * Roof Load
   * Expected gravity load on roof. This is used to calculate uplift pressure during the wind event
   */
  roof_load: number;
  /**
   * Design Terrain Category
   * Exposure category for design, as defined in ASCE 7
   */
  design_terrain_category: string;
  /**
   * Missile Env
   * Low / Medium / High categorization of surrounding missiles for each of four faces around building
   * @maxItems 4
   * @minItems 3
   */
  missile_env: MissileEnvEnum[];
  /**
   * Orientation
   * Orientation of the building relative to North
   */
  orientation: number;
}

/** Business */
export interface AppModelsRiskParametersRiskParametersSharedFateBusiness {
  /**
   * Indirect Revenue Loss
   * Daily losses due to functional downtime (e.g. loss of business or tolling fees collected from highways) [USD/Day]
   * @min 0
   */
  indirect_revenue_loss: number;
  /**
   * Economic Impact
   * Daily losses due to wider economic impact (e.g. a bridge goes down and the local economy is not able to function because of it) [USD/Day]
   * @min 0
   */
  economic_impact: number;
}

/** Contents */
export type AppModelsRiskParametersRiskParametersSharedFateContents = object;

/** General */
export type AppModelsRiskParametersRiskParametersSharedFateGeneral = object;

/** ImpedingFactors */
export type AppModelsRiskParametersRiskParametersSharedFateImpedingFactors =
  object;

/** Repair */
export interface AppModelsRiskParametersRiskParametersSharedFateRepair {
  /**
   * Repair Capacity
   * Number of assets that can be repaired simultaneously
   * @min 0
   */
  repair_capacity: number;
}

/** Seismic */
export type AppModelsRiskParametersRiskParametersSharedFateSeismic = object;

/** Wind */
export type AppModelsRiskParametersRiskParametersSharedFateWind = object;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title hazard-svc
 * @version 0.1.0
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  ping = {
    /**
     * No description
     *
     * @tags ping
     * @name RequestPong
     * @summary Request Pong
     * @request GET:/ping/
     * @secure
     */
    requestPong: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ping/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  archetypes = {
    /**
     * @description Get all versions of the Archetype Library for a specific hazard type within a specified scope.
     *
     * @tags archetypes
     * @name GetArchetypeLibraryVersions
     * @summary Get Archetype Library Versions
     * @request GET:/archetypes/versions
     * @secure
     */
    getArchetypeLibraryVersions: (
      query: {
        hazard_type: HazardType;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      params: RequestParams = {}
    ) =>
      this.request<Library[], HTTPValidationError>({
        path: `/archetypes/versions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve all versions of a specific archetype within a specified scope. This endpoint fetches all versions of the archetype specified by the provided archetype_id. You can optionally filter the versions by major and minor version numbers. Please note, if a minor version is specified, also a major version must also be provided.
     *
     * @tags archetypes
     * @name GetArchetypeVersions
     * @summary Get Archetype Versions
     * @request GET:/archetypes/{archetype_id}/versions
     * @secure
     */
    getArchetypeVersions: (
      archetypeId: string,
      query?: {
        /** Major Version */
        major_version?: number | null;
        /** Minor Version */
        minor_version?: number | null;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      params: RequestParams = {}
    ) =>
      this.request<ArchetypeResponse[], HTTPValidationError>({
        path: `/archetypes/${archetypeId}/versions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create archetypes in batch within a specified scope. If the scope is GLOBAL the archetypes will be available in all organizations. If the scope is ORGANIZATION, the archetypes will be available only within the organization. Only admin users can operate on the GLOBAL scope. This endpoint allows for the creation of multiple archetypes at once. The archetypes to be created are provided in a file, which is parsed and processed. The archetypes are related to a specific hazard type, which is provided as an argument to the endpoint. Steps: 1. Parse the provided file to extract the archetypes data. 2. Create a new Library associated with the specified hazard type, incrementing the major version number. 3. Create a new resource for the Library. 4. Create a new job for the batch creation of archetypes and run it 5. Return the information about the job that was created for the batch creation of archetypes. NOTE: Currently, if the batch creation fails, there is no automated rollback system. The rollback needs to be performed manually.
     *
     * @tags archetypes
     * @name CreateArchetypesInBatch
     * @summary Create Archetypes In Batch
     * @request POST:/archetypes/batch
     * @secure
     */
    createArchetypesInBatch: (
      query: {
        hazard_type: HazardType;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      data: BodyCreateArchetypesInBatchArchetypesBatchPost,
      params: RequestParams = {}
    ) =>
      this.request<SimpleJobResponse, HTTPValidationError>({
        path: `/archetypes/batch`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Create an archetype within a specified scope. If the scope is GLOBAL the archetype will be available in all organizations. If the scope is ORGANIZATION, the archetype will be available only within the organization. Only admin users can operate on the GLOBAL scope. This endpoint allows for the creation of a new archetype. The archetype data is provided in the request body. Steps: 1. Retrieve the Library for the specified hazard type. If it doesn't exist, a new one is created. 2. Generate a new archetype using the provided data. If the archetype already exists, its minor version is incremented. If it's a new archetype, its minor version is set to 1. 3. Update the Library with the timestamp of the update and the identity of the user who performed the update. 4. Return the newly created archetype.
     *
     * @tags archetypes
     * @name CreateArchetype
     * @summary Create Archetype
     * @request PATCH:/archetypes/
     * @secure
     */
    createArchetype: (
      data: CreateArchetypeRequest,
      query?: {
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      params: RequestParams = {}
    ) =>
      this.request<ArchetypeResponse, HTTPValidationError>({
        path: `/archetypes/`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a specific version of an archetype. This endpoint fetches the archetype specified by the provided archetype_id and version numbers. If no version numbers are provided, it defaults to the latest version.
     *
     * @tags archetypes
     * @name GetArchetype
     * @summary Get Archetype
     * @request GET:/archetypes/{archetype_id}
     * @secure
     */
    getArchetype: (
      archetypeId: string,
      query?: {
        /**
         * Major Version
         * @default 0
         */
        major_version?: number | null;
        /**
         * Minor Version
         * @default 0
         */
        minor_version?: number | null;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      params: RequestParams = {}
    ) =>
      this.request<ArchetypeResponse, HTTPValidationError>({
        path: `/archetypes/${archetypeId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Soft delete a specific version of an archetype. Notice that if the minor version is not provided, all the minor versions (for the provided major version) of the archetype are archived.
     *
     * @tags archetypes
     * @name DeleteArchetype
     * @summary Delete Archetype
     * @request DELETE:/archetypes/{archetype_id}
     * @secure
     */
    deleteArchetype: (
      archetypeId: string,
      query?: {
        /**
         * Major Version
         * @default 0
         */
        major_version?: number | null;
        /** Minor Version */
        minor_version?: number | null;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      params: RequestParams = {}
    ) =>
      this.request<Specification, HTTPValidationError>({
        path: `/archetypes/${archetypeId}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Search for archetypes based on various criteria. This endpoint allows for the searching of archetypes based on hazard type, major version, risk class, and attributes. The search results can be paginated using the cursor and limit parameters.
     *
     * @tags archetypes
     * @name SearchArchetypes
     * @summary Search Archetypes
     * @request POST:/archetypes/search
     * @secure
     */
    searchArchetypes: (
      query: {
        hazard_type: HazardType;
        /**
         * Major Version
         * @default 0
         */
        major_version?: number;
        /** Risk Class */
        risk_class?: RiskClassEnum | null;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
        /** Cursor */
        cursor?: string | null;
        /** Limit */
        limit?: number | null;
      },
      data: ArchetypeAttributes | null,
      params: RequestParams = {}
    ) =>
      this.request<PaginationResponseArchetypeResponse, HTTPValidationError>({
        path: `/archetypes/search`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  assessments = {
    /**
     * No description
     *
     * @tags assessments
     * @name CreateAssessment
     * @summary Create Assessment
     * @request POST:/assessments/
     * @secure
     */
    createAssessment: (data: AssessmentRequest, params: RequestParams = {}) =>
      this.request<AssessmentResponse, HTTPValidationError>({
        path: `/assessments/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all assessments for a specific `ref_type` (ASSET, GROUP) and `ref_id` (asset_id or group_id). > **🙌IMPORTANT** > Assessment outputs are not included in the response. > Use the `/assessments/{assessment_id}` endpoint to get the outputs for a specific assessment. Assessments can be additionally filtered by `tags` on the assessment: - `tags` - A list of strings to filter the assessments. Only assessments that match **all** the tags are returned.
     *
     * @tags assessments
     * @name GetAssessments
     * @summary Get Assessments
     * @request GET:/assessments/
     * @secure
     */
    getAssessments: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ReferenceTypeEnum;
        /**
         * Include Outputs
         * @default false
         */
        include_outputs?: boolean;
        /** Risk Class */
        risk_class?: RiskClassEnum | null;
        /** Subhazard Type */
        subhazard_type?: SubHazardType | null;
        /** Tags */
        tags?: string[] | null;
        /** Cursor */
        cursor?: string | null;
        /** Limit */
        limit?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedAssessmentInfo, HTTPValidationError>({
        path: `/assessments/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name CreateMultipleAssessments
     * @summary Create Multiple Assessments
     * @request POST:/assessments/multiple
     * @secure
     */
    createMultipleAssessments: (
      data: CreateMultipleAssessmentsRequest,
      params: RequestParams = {}
    ) =>
      this.request<AssessmentResponse[], HTTPValidationError>({
        path: `/assessments/multiple`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Search all assessments in the organization based on the provided query parameters. > **IMPORTANT** > Currently the search query is limited to Risk Class 1 and 2 assessments by default. > It was found that for some organizations there is a tremendous amount of hazard data attached to Risk Class 3 assessments, > which makes the search query slow and unresponsive. Therefore Risk Class 3 assessments are not included in the default search query, > but can be included by providing the `risk_classes` query parameter. This is a temporary solution until the search query is optimized. `tags` - A list of strings to filter the assessments. Only assessments that match **all** the tags are returned.
     *
     * @tags assessments
     * @name SearchAssessments
     * @summary Search Assessments
     * @request GET:/assessments/search
     * @secure
     */
    searchAssessments: (
      query?: {
        /**
         * Risk Classes
         * @default [1,2]
         */
        risk_classes?: RiskClassEnum[] | null;
        /** Tags */
        tags?: string[] | null;
        /**
         * Limit
         * @default 10
         */
        limit?: number | null;
        /**
         * Cursor
         * @default ""
         */
        cursor?: string | null;
        /** Ref Type */
        ref_type?: ReferenceTypeEnum | null;
        /** Ref Id */
        ref_id?: string | null;
        /** Hazard Type */
        hazard_type?: HazardType | null;
        /** Subhazard Type */
        subhazard_type?: SubHazardType | null;
        /** Status */
        status?: StatusEnum | null;
        /** Climate Scenario */
        climate_scenario?: ClimateScenario | null;
        /** Time Horizon */
        time_horizon?: number | null;
        /** Tag */
        tag?: string | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginationResponseAssessmentResponse, HTTPValidationError>({
        path: `/assessments/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a specific assessment by its ID. > **🙌IMPORTANT** > The assessment inputs are returned in the specified unit system. > The unit system is based on the organization's settings.
     *
     * @tags assessments
     * @name GetAssessmentById
     * @summary Get Assessment By Id
     * @request GET:/assessments/{assessment_id}
     * @secure
     */
    getAssessmentById: (assessmentId: string, params: RequestParams = {}) =>
      this.request<AssessmentResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Endpoint to cascade delete a specific assessment. When a specific assessment is deleted using this endpoint, all the following related data is also deleted: - hazards; - risk parameters; - assessment components; - assessment outputs; - engine runs (including input, outputs, and raw outputs).
     *
     * @tags assessments
     * @name DeleteAssessment
     * @summary Delete Assessment
     * @request DELETE:/assessments/{assessment_id}
     * @secure
     */
    deleteAssessment: (assessmentId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/assessments/${assessmentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name UpdateAssessment
     * @summary Update Assessment
     * @request PATCH:/assessments/{assessment_id}
     * @secure
     */
    updateAssessment: (
      assessmentId: string,
      data: AssessmentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<AssessmentResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name EditAssessmentStatus
     * @summary Edit Assessment Status
     * @request PATCH:/assessments/{assessment_id}/status
     * @secure
     */
    editAssessmentStatus: (
      assessmentId: string,
      query: {
        status: StatusEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<AssessmentResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/status`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name GetAssessmentOutput
     * @summary Get Assessment Output
     * @request GET:/assessments/{assessment_id}/output
     * @secure
     */
    getAssessmentOutput: (
      assessmentId: string,
      query?: {
        /** Data Key */
        data_key?: ConsequenceType | null;
        /** Percentile */
        percentile?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<AssessmentOutputResponse[], HTTPValidationError>({
        path: `/assessments/${assessmentId}/output`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name CreateAssessmentOutputs
     * @summary Create Assessment Outputs
     * @request POST:/assessments/{assessment_id}/output
     * @secure
     */
    createAssessmentOutputs: (
      assessmentId: string,
      data: DataRecordModelInput[],
      params: RequestParams = {}
    ) =>
      this.request<AssessmentOutputResponse[], HTTPValidationError>({
        path: `/assessments/${assessmentId}/output`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name DeleteAssessmentOutputs
     * @summary Delete Assessment Outputs
     * @request DELETE:/assessments/{assessment_id}/output
     * @secure
     */
    deleteAssessmentOutputs: (
      assessmentId: string,
      query?: {
        /** Data Key */
        data_key?: ConsequenceType | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<Specification, HTTPValidationError>({
        path: `/assessments/${assessmentId}/output`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update an assessment output given its ID and the ID of the assessment it belongs to. This endpoint allows updating of output records for which the value is a string, integer, or float. Other types are currently not supported.
     *
     * @tags assessments
     * @name UpdateAssessmentOutput
     * @summary Update Assessment Output
     * @request PATCH:/assessments/{assessment_id}/output/{output_id}
     * @secure
     */
    updateAssessmentOutput: (
      assessmentId: string,
      outputId: string,
      data: UpdateAssessmentOutputRequest,
      params: RequestParams = {}
    ) =>
      this.request<AssessmentOutputResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/output/${outputId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments
     * @name DeleteAssessmentOutput
     * @summary Delete Assessment Output
     * @request DELETE:/assessments/{assessment_id}/output/{output_id}
     * @secure
     */
    deleteAssessmentOutput: (
      assessmentId: string,
      outputId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/assessments/${assessmentId}/output/${outputId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name CreateEngineRun
     * @summary Create Engine Run
     * @request POST:/assessments/{assessment_id}/engine-run
     * @secure
     */
    createEngineRun: (
      assessmentId: string,
      data: CreateEngineRunRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<ReadEngineRun, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name GetRiskEngineInput
     * @summary Get Risk Engine Input
     * @request GET:/assessments/{assessment_id}/engine-run/risk-parameters
     * @secure
     */
    getRiskEngineInput: (assessmentId: string, params: RequestParams = {}) =>
      this.request<EngineModelInput, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run/risk-parameters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name GetEngineRun
     * @summary Get Engine Run
     * @request GET:/assessments/{assessment_id}/engine-run/{engine_run_id}
     * @secure
     */
    getEngineRun: (
      engineRunId: string,
      assessmentId: string,
      params: RequestParams = {}
    ) =>
      this.request<ReadEngineRun, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run/${engineRunId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name DeleteEngineRun
     * @summary Delete Engine Run
     * @request DELETE:/assessments/{assessment_id}/engine-run/{engine_run_id}
     * @secure
     */
    deleteEngineRun: (
      engineRunId: string,
      assessmentId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run/${engineRunId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name DownloadOutput
     * @summary Download Output
     * @request GET:/assessments/{assessment_id}/engine-run/{engine_run_id}/{hazard_id}/output
     * @secure
     */
    downloadOutput: (
      engineRunId: string,
      hazardId: string,
      assessmentId: string,
      params: RequestParams = {}
    ) =>
      this.request<RiskEngineUrlOutput, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run/${engineRunId}/${hazardId}/output`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name RiskEngineRawOutputIngestion
     * @summary Risk Engine Raw Output Ingestion
     * @request POST:/assessments/{assessment_id}/engine-run/{engine_run_id}/{hazard_id}/raw-output
     * @secure
     */
    riskEngineRawOutputIngestion: (
      engineRunId: string,
      hazardId: string,
      assessmentId: string,
      params: RequestParams = {}
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run/${engineRunId}/${hazardId}/raw-output`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name RiskEngineRunCompleted
     * @summary Risk Engine Run Completed
     * @request POST:/assessments/{assessment_id}/engine-run/{engine_run_id}/completed
     * @secure
     */
    riskEngineRunCompleted: (
      assessmentId: string,
      engineRunId: string,
      query: {
        /** Output File Path */
        output_file_path: string;
      },
      data: CompleteEngineRun,
      params: RequestParams = {}
    ) =>
      this.request<ReadEngineRun, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run/${engineRunId}/completed`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name RiskEngineRunFailed
     * @summary Risk Engine Run Failed
     * @request POST:/assessments/{assessment_id}/engine-run/{engine_run_id}/failed
     * @secure
     */
    riskEngineRunFailed: (
      assessmentId: string,
      engineRunId: string,
      data: CompleteEngineRun,
      params: RequestParams = {}
    ) =>
      this.request<ReadEngineRun, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run/${engineRunId}/failed`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/engine-run
     * @name GetPaginatedEngineRun
     * @summary Get Paginated Engine Run
     * @request GET:/assessments/{assessment_id}/engine-run/
     * @secure
     */
    getPaginatedEngineRun: (
      assessmentId: string,
      query?: {
        /** Status */
        status?: EngineRunStatus | null;
        /** Cursor */
        cursor?: string | null;
        /** Limit */
        limit?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedEngineRunInfo, HTTPValidationError>({
        path: `/assessments/${assessmentId}/engine-run/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/risk-parameters
     * @name CreateRiskParameters
     * @summary Create Risk Parameters
     * @request POST:/assessments/{assessment_id}/risk-parameters
     * @secure
     */
    createRiskParameters: (
      assessmentId: string,
      data: CreateRiskRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<RiskParametersResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/risk-parameters`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/risk-parameters
     * @name GetRiskParameters
     * @summary Get Risk Parameters
     * @request GET:/assessments/{assessment_id}/risk-parameters
     * @secure
     */
    getRiskParameters: (assessmentId: string, params: RequestParams = {}) =>
      this.request<RiskParametersResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/risk-parameters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/risk-parameters
     * @name UpdateRiskParameters
     * @summary Update Risk Parameters
     * @request PATCH:/assessments/{assessment_id}/risk-parameters
     * @secure
     */
    updateRiskParameters: (
      assessmentId: string,
      data: UpdateRiskParametersRequest,
      params: RequestParams = {}
    ) =>
      this.request<RiskParametersResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/risk-parameters`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/risk-parameters
     * @name DeleteRiskParameters
     * @summary Delete Risk Parameters
     * @request DELETE:/assessments/{assessment_id}/risk-parameters
     * @secure
     */
    deleteRiskParameters: (assessmentId: string, params: RequestParams = {}) =>
      this.request<RiskParametersSharedFateResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/risk-parameters`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/risk-parameters
     * @name GetSharedFateRiskParameters
     * @summary Get Shared Fate Risk Parameters
     * @request GET:/assessments/{assessment_id}/shared-fate/risk-parameters
     * @secure
     */
    getSharedFateRiskParameters: (
      assessmentId: string,
      params: RequestParams = {}
    ) =>
      this.request<RiskParametersSharedFateResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/shared-fate/risk-parameters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/risk-parameters
     * @name CreateSharedFateRiskParameters
     * @summary Create Shared Fate Risk Parameters
     * @request POST:/assessments/{assessment_id}/shared-fate/risk-parameters
     * @secure
     */
    createSharedFateRiskParameters: (
      assessmentId: string,
      data: CreateRiskParametersSharedFateRequest,
      params: RequestParams = {}
    ) =>
      this.request<RiskParametersSharedFateResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/shared-fate/risk-parameters`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/risk-parameters
     * @name UpdateSharedFateRiskParameters
     * @summary Update Shared Fate Risk Parameters
     * @request PATCH:/assessments/{assessment_id}/shared-fate/risk-parameters
     * @secure
     */
    updateSharedFateRiskParameters: (
      assessmentId: string,
      data: UpdateRiskParametersSharedFateRequest,
      params: RequestParams = {}
    ) =>
      this.request<RiskParametersSharedFateResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/shared-fate/risk-parameters`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/risk-parameters
     * @name DeleteRiskParametersSharedFate
     * @summary Delete Risk Parameters Shared Fate
     * @request DELETE:/assessments/{assessment_id}/shared-fate/risk-parameters
     * @secure
     */
    deleteRiskParametersSharedFate: (
      assessmentId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/assessments/${assessmentId}/shared-fate/risk-parameters`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new assessment component for a given assessment. **Note:** Floor numbering starts from 0, including floors below ground.
     *
     * @tags assessments/components
     * @name CreateAssessmentComponent
     * @summary Create Assessment Component
     * @request POST:/assessments/{assessment_id}/component
     * @secure
     */
    createAssessmentComponent: (
      assessmentId: string,
      query: {
        region: ComponentRegion;
      },
      data: CreateAssessmentComponentRequest,
      params: RequestParams = {}
    ) =>
      this.request<AssessmentComponentResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/component`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/components
     * @name GetAssessmentComponent
     * @summary Get Assessment Component
     * @request GET:/assessments/{assessment_id}/component/{NISTR}/{floor}
     * @secure
     */
    getAssessmentComponent: (
      assessmentId: string,
      nistr: string,
      floor: number,
      params: RequestParams = {}
    ) =>
      this.request<AssessmentComponentResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/component/${nistr}/${floor}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/components
     * @name UpdateAssessmentComponents
     * @summary Update Assessment Components
     * @request PATCH:/assessments/{assessment_id}/component/{NISTR}/{floor}
     * @secure
     */
    updateAssessmentComponents: (
      assessmentId: string,
      nistr: string,
      floor: number,
      data: UpdateAssessmentComponentRequest,
      params: RequestParams = {}
    ) =>
      this.request<AssessmentComponentResponse, HTTPValidationError>({
        path: `/assessments/${assessmentId}/component/${nistr}/${floor}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/components
     * @name DeleteAssessmentComponent
     * @summary Delete Assessment Component
     * @request DELETE:/assessments/{assessment_id}/component/{NISTR}/{floor}
     * @secure
     */
    deleteAssessmentComponent: (
      assessmentId: string,
      nistr: string,
      floor: number,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/assessments/${assessmentId}/component/${nistr}/${floor}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assessments/components
     * @name GetAssessmentComponents
     * @summary Get Assessment Components
     * @request GET:/assessments/{assessment_id}/components
     * @secure
     */
    getAssessmentComponents: (
      assessmentId: string,
      query?: {
        /** Floor */
        floor?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<AssessmentComponentResponse[], HTTPValidationError>({
        path: `/assessments/${assessmentId}/components`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  components = {
    /**
     * No description
     *
     * @tags components
     * @name GetPaginatedComponents
     * @summary Get Paginated Components
     * @request GET:/components/
     * @secure
     */
    getPaginatedComponents: (
      query?: {
        /** Nistr */
        NISTR?: string | null;
        /** Subgroup */
        subgroup?: string | null;
        /** Component */
        component?: string | null;
        /** Risk Engine */
        risk_engine?: HazardType | null;
        /** Region */
        region?: ComponentRegion | null;
        /** Cursor */
        cursor?: string | null;
        /**
         * Limit
         * @default 10
         */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginationResponse, HTTPValidationError>({
        path: `/components/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags components
     * @name CreateComponent
     * @summary Create Component
     * @request POST:/components/
     * @secure
     */
    createComponent: (
      data: CreateComponentRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/components/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags components
     * @name GetComponentForRiskEngineAndRegion
     * @summary Get Component For Risk Engine And Region
     * @request GET:/components/{NISTR}/{hazard_type}/{region}
     * @secure
     */
    getComponentForRiskEngineAndRegion: (
      nistr: string,
      hazardType: HazardType,
      region: ComponentRegion,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/components/${nistr}/${hazardType}/${region}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags components
     * @name DeleteComponent
     * @summary Delete Component
     * @request DELETE:/components/{NISTR}/{hazard_type}/{region}
     * @secure
     */
    deleteComponent: (
      nistr: string,
      hazardType: HazardType,
      region: ComponentRegion,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/components/${nistr}/${hazardType}/${region}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  hazards = {
    /**
     * @description Returns the list of sub-hazards that are supported in IRIS. If no parameters are provided, all sub-hazards are returned.
     *
     * @tags hazards
     * @name GetSubHazards
     * @summary Get Sub Hazards
     * @request GET:/hazards/sub-hazards
     * @secure
     */
    getSubHazards: (
      query?: {
        /** Sub Hazard */
        sub_hazard?: SubHazardType | null;
        /** Hazard Type */
        hazard_type?: HazardType | null;
        /** Hazard Category */
        hazard_category?: HazardCategory | null;
        /** Risk Class Engines */
        risk_class_engines?: RiskClassEnum[] | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<SubHazardBase[], HTTPValidationError>({
        path: `/hazards/sub-hazards`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the details of a specific sub-hazard.
     *
     * @tags hazards
     * @name GetDetailsForSubHazard
     * @summary Get Details For Sub Hazard
     * @request GET:/hazards/sub-hazards/{sub_hazard}
     * @secure
     */
    getDetailsForSubHazard: (
      subHazard: SubHazardType,
      params: RequestParams = {}
    ) =>
      this.request<SubHazard, HTTPValidationError>({
        path: `/hazards/sub-hazards/${subHazard}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags hazards
     * @name CreateHazard
     * @summary Create Hazard
     * @request POST:/hazards/
     * @secure
     */
    createHazard: (data: CreateHazardRequest, params: RequestParams = {}) =>
      this.request<HazardResponse, HTTPValidationError>({
        path: `/hazards/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags hazards
     * @name GetHazardsByAssessmentId
     * @summary Get Hazards By Assessment Id
     * @request GET:/hazards/
     * @secure
     */
    getHazardsByAssessmentId: (
      query: {
        /** Assessment Id */
        assessment_id: string;
        /** Frequency Metric */
        frequency_metric?: FrequencyMetric | null;
        /** Limit */
        limit?: number | null;
        /** Cursor */
        cursor?: string | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginationResponseHazardResponse, HTTPValidationError>({
        path: `/hazards/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags hazards
     * @name GetHazard
     * @summary Get Hazard
     * @request GET:/hazards/{hazard_id}
     * @secure
     */
    getHazard: (
      hazardId: string,
      query: {
        /** Assessment Id */
        assessment_id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<HazardResponse, HTTPValidationError>({
        path: `/hazards/${hazardId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags hazards
     * @name EditHazard
     * @summary Edit Hazard
     * @request PATCH:/hazards/{hazard_id}
     * @secure
     */
    editHazard: (
      hazardId: string,
      query: {
        /** Assessment Id */
        assessment_id: string;
      },
      data: EditHazardInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<HazardResponse, HTTPValidationError>({
        path: `/hazards/${hazardId}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags hazards
     * @name DeleteHazard
     * @summary Delete Hazard
     * @request DELETE:/hazards/{hazard_id}
     * @secure
     */
    deleteHazard: (
      hazardId: string,
      query: {
        /** Assessment Id */
        assessment_id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/hazards/${hazardId}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint can be used to create an hazard object from a json file containing custom hazard data. Currently only: - SEISMIC hazard type is supported when providing EDPs (Engineering Demand Parameters). - SEISMIC and WIND hazard types are supported when providing the VECTOR of REALIZATIONS (hazard_distribution=CUSTOM)
     *
     * @tags hazards
     * @name CreateHazardFromResource
     * @summary Create Hazard From Resource
     * @request POST:/hazards/resource
     * @secure
     */
    createHazardFromResource: (
      data: BodyCreateHazardFromResourceHazardsResourcePost,
      params: RequestParams = {}
    ) =>
      this.request<HazardResponse, HTTPValidationError>({
        path: `/hazards/resource`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint can be used to import hazard data from the HAPI service for a specific assessment. It will pull the point or footprint geometry from the associated asset, and query external APIs for the hazard specified in the provided assessment. The data from HAPI is used to create hazard objects for the found intensity measure(s) and return period(s). In case any visual output is available (i.e. maps), these are created and associated to the given hazard. If maps are created, they will also be downloadable and viewable to the user. Request parameters: - **assessment_id**: The assessment to query the hazard information for. - **shape_type**: Whether a point or area query should be used. _Note_: The geometry is pulled from the asset. If not available, no query to HAPI can be performed. The request body has the following **optional fields**: - **time_horizon**: Time horizon that incorporates the effect of climate change, e.g., 2052. If not provided, the most current time horizon of the hazard model will be used. - **climate_scenario**: Climate scenario that represents future levels of greenhouse gas concentrations throughout the 21st century, e.g., SSP2_4.5 - **buffer_distance**: The distance in km, around the provided point or area, for which the clipped raster map(s) (if they exist) should be returned. - **job_number**: The project ID. Required if using a paid dataset. - **model_id**: The id of the hazard model requested. Note that if left blank the preferred model will be used.
     *
     * @tags hazards
     * @name ImportHazardData
     * @summary Import Hazard Data
     * @request POST:/hazards/import/
     * @secure
     */
    importHazardData: (
      query: {
        /** Assessment Id */
        assessment_id: string;
        shape_type: ShapeType;
      },
      data: HazardImportRequest,
      params: RequestParams = {}
    ) =>
      this.request<SimpleJobResponse, HTTPValidationError>({
        path: `/hazards/import/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  engines = {
    /**
     * No description
     *
     * @tags engines
     * @name GetRiskEngines
     * @summary Get Risk Engines
     * @request GET:/engines/
     * @secure
     */
    getRiskEngines: (params: RequestParams = {}) =>
      this.request<PaginationResponseRiskEngineResponse, any>({
        path: `/engines/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieves required input parameters for a given assessment. The required input parameters are retrieved from the risk engine associated with the assessment. The risk engine associated with an assessment is determined by its `engine_id` attribute
     *
     * @tags engines
     * @name GetInputParameters
     * @summary Get Input Parameters
     * @request POST:/engines/parameters
     * @secure
     */
    getInputParameters: (
      query: {
        /** Assessment Id */
        assessment_id: string;
      },
      data: ListInputParameters | null,
      params: RequestParams = {}
    ) =>
      this.request<InputParameter[], HTTPValidationError>({
        path: `/engines/parameters`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags engines
     * @name RunRiskEngine
     * @summary Run Risk Engine
     * @request POST:/engines/{assessment_id}
     * @secure
     */
    runRiskEngine: (assessmentId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/engines/${assessmentId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags engines
     * @name GetAllInputParameters
     * @summary Get All Input Parameters
     * @request GET:/engines/all_parameters
     * @secure
     */
    getAllInputParameters: (
      query?: {
        /** Hazard Type */
        hazard_type?: HazardType | null;
        /** Risk Class */
        risk_class?: RiskClassEnum | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<InputParameter[], HTTPValidationError>({
        path: `/engines/all_parameters`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  riskRatings = {
    /**
     * @description Retrieves risk ratings for a batch of assets or groups, based on hazards and consequences. The risk ratings are then sorted, with the riskiest ratings appearing first. Each risk rating is given a risk_rating_score, which is a number between -1 (Not Assessed) and 9 (Catastrophic). The higher the score, the greater the level of risk.
     *
     * @tags risk-ratings
     * @name SearchRiskRating
     * @summary Search Risk Rating
     * @request POST:/risk-ratings/search
     * @secure
     */
    searchRiskRating: (
      query: {
        ref_type: ReferenceTypeEnum;
        /**
         * Assessment Type
         * @default "CURRENT"
         */
        assessment_type?: AssessmentTypeEnum | null;
        /** Rcp Scenario */
        rcp_scenario?: RCPScenarioEnum | null;
        /** Time Horizon */
        time_horizon?: number | null;
      },
      data: RiskRatingBatchRequest,
      params: RequestParams = {}
    ) =>
      this.request<RatingInfo[], HTTPValidationError>({
        path: `/risk-ratings/search`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieves the high tide risk ratings for a batch of assets or groups, based on the given rating aspect. If group_by is HAZARD the highest risk rating across all consequences for each hazard is returned. If group_by is CONSEQUENCE the highest risk rating across all hazards for each consequence is returned. The retrieved risk ratings are ordered with the highest risk rating first, based on the severity of the hazard across all assets or groups, measured by the risk_sort_score which is a number between -1 (Not Assessed) and 9 (Catastrophic). The higher the score, the greater the level of risk.
     *
     * @tags risk-ratings
     * @name GetHighTideRiskRating
     * @summary Get High Tide Risk Rating
     * @request POST:/risk-ratings/high-tide
     * @secure
     */
    getHighTideRiskRating: (
      query: {
        ref_type: ReferenceTypeEnum;
        /**
         * Assessment Type
         * @default "CURRENT"
         */
        assessment_type?: AssessmentTypeEnum | null;
        /**
         * Group By
         * @default "HAZARD"
         */
        group_by?: RiskRatingGrouping | null;
        /** Rcp Scenario */
        rcp_scenario?: RCPScenarioEnum | null;
        /** Time Horizon */
        time_horizon?: number;
      },
      data: ReferenceIds,
      params: RequestParams = {}
    ) =>
      this.request<RatingInfo[], HTTPValidationError>({
        path: `/risk-ratings/high-tide`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Calculates the top rated hazards for assets or groups based on their risk ratings. Returns a list of objects, each representing a hazard and the number of assets or groups that have been rated with each possible risk rating. The list is sorted in descending order based on the risk_rating_score which is used to evaluate the severity of the hazard across all assets or groups. The higher the score, the greater the level of risk.
     *
     * @tags risk-ratings
     * @name GetTopHazards
     * @summary Get Top Hazards
     * @request POST:/risk-ratings/top-hazards
     * @secure
     */
    getTopHazards: (
      query: {
        ref_type: ReferenceTypeEnum;
        /**
         * Assessment Type
         * @default "CURRENT"
         */
        assessment_type?: AssessmentTypeEnum | null;
        /** Rcp Scenario */
        rcp_scenario?: RCPScenarioEnum | null;
        /** Time Horizon */
        time_horizon?: number;
      },
      data: ReferenceIds,
      params: RequestParams = {}
    ) =>
      this.request<RatingHazardsCount[], HTTPValidationError>({
        path: `/risk-ratings/top-hazards`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags risk-ratings
     * @name GetRiskRatingHazards
     * @summary Get Risk Rating Hazards
     * @request GET:/risk-ratings/hazards
     * @secure
     */
    getRiskRatingHazards: (params: RequestParams = {}) =>
      this.request<RiskRatingHazardsEnum[], any>({
        path: `/risk-ratings/hazards`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a list of the latest version of the risk ratings for the specified reference ID and type.
     *
     * @tags risk-ratings
     * @name GetRiskRatings
     * @summary Get Risk Ratings
     * @request GET:/risk-ratings/
     * @secure
     */
    getRiskRatings: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ReferenceTypeEnum;
        /** Assessment Type */
        assessment_type?: AssessmentTypeEnum | null;
        /** Rcp Scenario */
        rcp_scenario?: RCPScenarioEnum | null;
        /** Time Horizon */
        time_horizon?: number | null;
        /** Status */
        status?: StatusEnum | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<RiskRatingResponse[], HTTPValidationError>({
        path: `/risk-ratings/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags risk-ratings
     * @name CreateRiskRating
     * @summary Create Risk Rating
     * @request POST:/risk-ratings/
     * @secure
     */
    createRiskRating: (data: RiskRatingRequest, params: RequestParams = {}) =>
      this.request<RiskRatingResponse, HTTPValidationError>({
        path: `/risk-ratings/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a list of all versions for the specified risk rating ID.
     *
     * @tags risk-ratings
     * @name GetRiskRatingVersions
     * @summary Get Risk Rating Versions
     * @request GET:/risk-ratings/{risk_rating_id}/versions
     * @secure
     */
    getRiskRatingVersions: (riskRatingId: string, params: RequestParams = {}) =>
      this.request<RiskRatingResponse[], HTTPValidationError>({
        path: `/risk-ratings/${riskRatingId}/versions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a specific version of a risk rating by its ID.
     *
     * @tags risk-ratings
     * @name GetRiskRating
     * @summary Get Risk Rating
     * @request GET:/risk-ratings/{risk_rating_id}
     * @secure
     */
    getRiskRating: (
      riskRatingId: string,
      query?: {
        /**
         * Version
         * @default 0
         */
        version?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<RiskRatingResponse, HTTPValidationError>({
        path: `/risk-ratings/${riskRatingId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags risk-ratings
     * @name UpdateRiskRating
     * @summary Update Risk Rating
     * @request PATCH:/risk-ratings/{risk_rating_id}
     * @secure
     */
    updateRiskRating: (
      riskRatingId: string,
      data: UpdateRiskRatingRequest,
      query?: {
        /**
         * Version
         * @default 0
         */
        version?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<RiskRatingResponse, HTTPValidationError>({
        path: `/risk-ratings/${riskRatingId}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags risk-ratings
     * @name DeleteRiskRating
     * @summary Delete Risk Rating
     * @request DELETE:/risk-ratings/{risk_rating_id}
     * @secure
     */
    deleteRiskRating: (
      riskRatingId: string,
      query?: {
        /**
         * Version
         * @default 0
         */
        version?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/risk-ratings/${riskRatingId}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags risk-ratings
     * @name UpdateRiskRatingStatus
     * @summary Update Risk Rating Status
     * @request PATCH:/risk-ratings/{risk_rating_id}/status
     * @secure
     */
    updateRiskRatingStatus: (
      riskRatingId: string,
      query: {
        status: StatusEnum;
        /**
         * Version
         * @default 0
         */
        version?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<RiskRatingResponse, HTTPValidationError>({
        path: `/risk-ratings/${riskRatingId}/status`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Generates an executive summary of the risk-ratings employing the OpenAI ChatGPT API. Input parameters: - **risk_rating_id**: The risk rating ID for which you want to generate a executive summary - **additional_instructions [Optional]**: A string of additional instructions that tells ChatGPT what change in the summary. E.g., "omit the asset address from the first paragraph."
     *
     * @tags risk-ratings
     * @name GenerateRiskRatingSummary
     * @summary Generate Risk Rating Summary
     * @request PATCH:/risk-ratings/{risk_rating_id}/summary
     * @secure
     */
    generateRiskRatingSummary: (
      riskRatingId: string,
      query?: {
        /**
         * Additional Instructions
         * @default ""
         */
        additional_instructions?: string | null;
        /**
         * Version
         * @default 0
         */
        version?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<RiskRatingResponse, HTTPValidationError>({
        path: `/risk-ratings/${riskRatingId}/summary`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  analytics = {
    /**
     * No description
     *
     * @tags analytics
     * @name GetOutputDataForAssets
     * @summary Get Output Data For Assets
     * @request POST:/analytics/asset/output
     * @secure
     */
    getOutputDataForAssets: (
      data: AssetsOutputRequest,
      params: RequestParams = {}
    ) =>
      this.request<AssetsOutputResponse[], HTTPValidationError>({
        path: `/analytics/asset/output`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name RequestBuildingOutputData
     * @summary Request Building Output Data
     * @request GET:/analytics/{assessment_id}/{engine_run_id}/output
     * @secure
     */
    requestBuildingOutputData: (
      assessmentId: string,
      engineRunId: string,
      params: RequestParams = {}
    ) =>
      this.request<DataRecordModelOutput[], HTTPValidationError>({
        path: `/analytics/${assessmentId}/${engineRunId}/output`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name RequestComponentOutputData
     * @summary Request Component Output Data
     * @request GET:/analytics/{assessment_id}/{engine_run_id}/component/risk-class
     * @secure
     */
    requestComponentOutputData: (
      assessmentId: string,
      engineRunId: string,
      params: RequestParams = {}
    ) =>
      this.request<ComponentRiskClassModel[], HTTPValidationError>({
        path: `/analytics/${assessmentId}/${engineRunId}/component/risk-class`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name DeleteEngineRunOutput
     * @summary Delete Engine Run Output
     * @request DELETE:/analytics/{assessment_id}/{engine_run_id}
     * @secure
     */
    deleteEngineRunOutput: (
      assessmentId: string,
      engineRunId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/analytics/${assessmentId}/${engineRunId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name GetSharedFate
     * @summary Get Shared Fate
     * @request GET:/analytics/shared-fate/{assessment_id}
     * @secure
     */
    getSharedFate: (assessmentId: string, params: RequestParams = {}) =>
      this.request<DataRecordModelOutput[], HTTPValidationError>({
        path: `/analytics/shared-fate/${assessmentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name ShareFateDataAggregation
     * @summary Share Fate Data Aggregation
     * @request POST:/analytics/shared-fate/{assessment_id}
     * @secure
     */
    shareFateDataAggregation: (
      assessmentId: string,
      data: EngineRunsList,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/analytics/shared-fate/${assessmentId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name DeleteSharedFateOutput
     * @summary Delete Shared Fate Output
     * @request DELETE:/analytics/shared-fate/{assessment_id}/output
     * @secure
     */
    deleteSharedFateOutput: (
      assessmentId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/analytics/shared-fate/${assessmentId}/output`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name CreateRecoveryPlots
     * @summary Create Recovery Plots
     * @request POST:/analytics/shared-fate/{assessment_id}/recovery-plots
     * @secure
     */
    createRecoveryPlots: (
      assessmentId: string,
      data: RecoveryPlotRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<RecoveryPlotResponseApi[], HTTPValidationError>({
        path: `/analytics/shared-fate/${assessmentId}/recovery-plots`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name GetRecoveryPlots
     * @summary Get Recovery Plots
     * @request GET:/analytics/shared-fate/{assessment_id}/recovery-plots
     * @secure
     */
    getRecoveryPlots: (assessmentId: string, params: RequestParams = {}) =>
      this.request<RecoveryPlotResponseApi[], HTTPValidationError>({
        path: `/analytics/shared-fate/${assessmentId}/recovery-plots`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags analytics
     * @name DeleteRecoveryPlots
     * @summary Delete Recovery Plots
     * @request DELETE:/analytics/shared-fate/{assessment_id}/recovery-plots
     * @secure
     */
    deleteRecoveryPlots: (assessmentId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/analytics/shared-fate/${assessmentId}/recovery-plots`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  map = {
    /**
     * @description Retrieves all map layers that are associated with a specific asset or group. Filtering can be performed on the *hazard_type* and *upload_status*. - **ref_id**: the unique identifier of the asset or group that the layer is linked to. - **ref_type**: the type of object the layer is linked to, e.g. ASSET or GROUP. - **hazard_type**: the type of hazard the layer is linked to, e.g. geophysical_seismic. - **upload_status**: the status of the map layer upload, e.g. COMPLETED.
     *
     * @tags map
     * @name GetMapLayers
     * @summary Get Map Layers
     * @request GET:/map/layer
     * @secure
     */
    getMapLayers: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ReferenceTypeEnum;
        /** Hazard Type */
        hazard_type?: RiskRatingHazardsEnum | AllHazardsEnum | null;
        /** Upload Status */
        upload_status?: EngineRunStatus | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<MapLayerInfo[], HTTPValidationError>({
        path: `/map/layer`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Uploads a map layer, linked to an asset or group, to Mapbox. By default, the status of the layer upload is set to IN_PROGRESS. To refresh the status, use `PATCH /map/layer/{layer_id}/status` - **ref_id**: the unique identifier of the asset or group that the layer is linked to. - **ref_type**: the type of object the layer is linked to, e.g. ASSET or GROUP. - **name**: a meaningful name describing the contents in the map layer. - **hazard_type**: the type of hazard the layer is linked to, e.g. geophysical_seismic. - **description**: a longer description of the contents of the map layer. - **file**: the raster data that needs to be uploaded, stored in GeoTIFF format. The following requirements hold: - The file should be an 8-bit GeoTIFF; - The coordinate reference system (CRS) should be set to EPSG:3857 (WGS84 Web Mercator); - Styling will only be applied if the renedered image is stored with a style, otherwise it will be grayscale.
     *
     * @tags map
     * @name UploadMapLayer
     * @summary Upload Map Layer
     * @request POST:/map/layer
     * @secure
     */
    uploadMapLayer: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ReferenceTypeEnum;
        /** Name */
        name: string;
        /**
         * Hazard Type
         * @default "all_hazards"
         */
        hazard_type?: RiskRatingHazardsEnum | AllHazardsEnum;
        /** Description */
        description?: string | null;
      },
      data: BodyUploadMapLayerMapLayerPost,
      params: RequestParams = {}
    ) =>
      this.request<MapLayerInfo, HTTPValidationError>({
        path: `/map/layer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieves the latest upload status for a specific map layer. This endpoint should be pinged after the `POST /map/layer` or `PATCH /map/layer/{layer_id}/file` API call is used. - **layer_id**: the unique identifier of the map layer. - **ref_id**: the unique identifier of the asset or group that the layer is linked to. - **ref_type**: the type of object the layer is linked to, e.g. ASSET or GROUP.
     *
     * @tags map
     * @name RefreshMapLayerStatus
     * @summary Refresh Map Layer Status
     * @request PATCH:/map/layer/{layer_id}/status
     * @secure
     */
    refreshMapLayerStatus: (
      layerId: string,
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ReferenceTypeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<MapLayerInfo, HTTPValidationError>({
        path: `/map/layer/${layerId}/status`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Overwrites the specified map layer file in Mapbox with a new version. - **layer_id**: the unique identifier of the map layer. - **ref_id**: the unique identifier of the asset or group that the layer is linked to. - **ref_type**: the type of object the layer is linked to, e.g. ASSET or GROUP. - **file**: the raster data that needs to be uploaded, stored in GeoTIFF format. The following requirements hold: - The file should be an 8-bit GeoTIFF; - The coordinate reference system (CRS) should be set to EPSG:3857 (WGS84 Web Mercator); - Styling will only be applied if the renedered image is stored with a style, otherwise it will be grayscale.
     *
     * @tags map
     * @name UpdateMapLayerFile
     * @summary Update Map Layer File
     * @request PATCH:/map/layer/{layer_id}/file
     * @secure
     */
    updateMapLayerFile: (
      layerId: string,
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ReferenceTypeEnum;
      },
      data: BodyUpdateMapLayerFileMapLayerLayerIdFilePatch,
      params: RequestParams = {}
    ) =>
      this.request<MapLayerInfo, HTTPValidationError>({
        path: `/map/layer/${layerId}/file`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Updates the general information for a specific map layer. - **layer_id**: the unique identifier of the map layer. - **ref_id**: the unique identifier of the asset or group that the layer is linked to. - **ref_type**: the type of object the layer is linked to, e.g. ASSET or GROUP. - **body**: the request body with the fields to update. The following fields are currently supported: - *name*: a meaningful name describing the contents in the map layer. - *hazard_type*: the type of hazard the layer is linked to, e.g. geophysical_seismic. - *description*: a longer description of the contents of the map layer.
     *
     * @tags map
     * @name UpdateMapLayerInformation
     * @summary Update Map Layer Information
     * @request PATCH:/map/layer/{layer_id}/info
     * @secure
     */
    updateMapLayerInformation: (
      layerId: string,
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ReferenceTypeEnum;
      },
      data: MapLayerUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<MapLayerInfo, HTTPValidationError>({
        path: `/map/layer/${layerId}/info`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes the map layer from a specific asset or group. - **layer_id**: the unique identifier of the map layer. - **ref_id**: the unique identifier of the asset or group that the layer is linked to. - **ref_type**: the type of object the layer is linked to, e.g. ASSET or GROUP.
     *
     * @tags map
     * @name DeleteMapLayer
     * @summary Delete Map Layer
     * @request DELETE:/map/layer/{layer_id}
     * @secure
     */
    deleteMapLayer: (
      layerId: string,
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ReferenceTypeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/map/layer/${layerId}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  resources = {
    /**
     * @description Get all resources that belong to a reference object (Ex: Hazard or Risk Rating) with a `ref_id` and `ref_type` Optionally can filter the resources returned based on the `resource_type`. - **ref_id**: the unique identifier of the object that the file is linked to. - **ref_type**: the type of object the file is linked to, e.g. HAZARD or RISK RATING. - **resource_type**: the type of resource that the file represents. MAP_LAYER resources should be rendered on maps.
     *
     * @tags resources
     * @name GetResources
     * @summary Get Resources
     * @request GET:/resources/
     * @secure
     */
    getResources: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
        /** Resource Type */
        resource_type?: ResourceTypeEnum | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceResponse[], HTTPValidationError>({
        path: `/resources/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Upload a file and attach it to a reference object (ex a Hazard or Risk Rating) If the upload is successful, a resource_id will be returned which can be used to retrieve the file. The uploaded file can be downloaded using `GET /resource/{resource_id}/download` - **ref_id**: the unique identifier of the object that the file is linked to. - **ref_type**: the type of object the file is linked to, e.g. HAZARD or RISK RATING. - **file**: the file to be uploaded - **resource_type**: the type of resource that the file represents. MAP_LAYER resources should be rendered on maps. - **metadata** : an Base64 encoded byte str that encodes a dictionary of meta data about the file
     *
     * @tags resources
     * @name UploadFile
     * @summary Upload File
     * @request POST:/resources/
     * @secure
     */
    uploadFile: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
        /**
         * Resource Type
         * @default "GENERAL"
         */
        resource_type?: ResourceTypeEnum;
        /** Metadata */
        metadata?: string | null;
      },
      data: BodyUploadFileResourcesPost,
      params: RequestParams = {}
    ) =>
      this.request<ResourceResponse, HTTPValidationError>({
        path: `/resources/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Get information about a file based on its resource_id. This endpoint only returns information about the file. To download the file use `GET /resource/{resource_id}/download` - **resource_id**: the UUID of the resource
     *
     * @tags resources
     * @name GetResource
     * @summary Get Resource
     * @request GET:/resources/{resource_id}
     * @secure
     */
    getResource: (resourceId: string, params: RequestParams = {}) =>
      this.request<ResourceResponse, HTTPValidationError>({
        path: `/resources/${resourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update the metadata and resource_type of a file based on its resource_id. - **resource_id**: the UUID of the resource The following is expected in the request body: - **resource_type**: the type of resource that the file represents. MAP_LAYER resources should be rendered on maps. - **metadata** : an dictionary of meta data about the file. Any valid dictionary can be used here to add metadata to the file.
     *
     * @tags resources
     * @name UpdateResource
     * @summary Update Resource
     * @request PATCH:/resources/{resource_id}
     * @secure
     */
    updateResource: (
      resourceId: string,
      data: UpdateResourceRequest,
      params: RequestParams = {}
    ) =>
      this.request<ResourceResponse, HTTPValidationError>({
        path: `/resources/${resourceId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete an uploaded file based on its `resource_id`. This will permanently delete the file from the file store. - **resource_id**: the UUID of the resource
     *
     * @tags resources
     * @name DeleteFile
     * @summary Delete File
     * @request DELETE:/resources/{resource_id}
     * @secure
     */
    deleteFile: (resourceId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/resources/${resourceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Download a file based on a resource_id. A `presigned` URL will be returned which the file can be downloaded from using a GET request. - **resource_id**: the UUID of the resource
     *
     * @tags resources
     * @name DownloadFile
     * @summary Download File
     * @request GET:/resources/{resource_id}/download
     * @secure
     */
    downloadFile: (resourceId: string, params: RequestParams = {}) =>
      this.request<DownloadFileResponse, HTTPValidationError>({
        path: `/resources/${resourceId}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  jobs = {
    /**
     * No description
     *
     * @tags jobs
     * @name GetJobsForObject
     * @summary Get Jobs For Object
     * @request GET:/jobs/
     * @secure
     */
    getJobsForObject: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
        /**
         * Limit
         * @default 10
         */
        limit?: number;
        /** Cursor */
        cursor?: string | null;
        /** Type */
        type?: JobType | null;
        /** Status */
        status?: StatusEnum | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedJobInfo, HTTPValidationError>({
        path: `/jobs/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name GetJob
     * @summary Get Job
     * @request GET:/jobs/{job_id}
     * @secure
     */
    getJob: (jobId: string, params: RequestParams = {}) =>
      this.request<Job, HTTPValidationError>({
        path: `/jobs/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name DeleteJob
     * @summary Delete Job
     * @request DELETE:/jobs/{job_id}
     * @secure
     */
    deleteJob: (jobId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/jobs/${jobId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  adjacencyRisk = {
    /**
     * No description
     *
     * @tags adjacency-risk
     * @name ComputationAdjacencyRiskBatch
     * @summary Computation Adjacency Risk Batch
     * @request POST:/adjacency-risk/batch
     * @secure
     */
    computationAdjacencyRiskBatch: (
      data: AdjacencyRiskBatchRequestApi[],
      params: RequestParams = {}
    ) =>
      this.request<AdjacencyRiskBatchResponseApi, HTTPValidationError>({
        path: `/adjacency-risk/batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjacency-risk
     * @name ComputationAdjacencyRisk
     * @summary Computation Adjacency Risk
     * @request POST:/adjacency-risk/
     * @secure
     */
    computationAdjacencyRisk: (
      data: AdjacencyInfo,
      params: RequestParams = {}
    ) =>
      this.request<AdjacencyRiskResponseApi[], HTTPValidationError>({
        path: `/adjacency-risk/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjacency-risk
     * @name GetAdjacencyRisk
     * @summary Get Adjacency Risk
     * @request GET:/adjacency-risk/site/{site_id}
     * @secure
     */
    getAdjacencyRisk: (
      siteId: string,
      query?: {
        /** Adjacency Type */
        adjacency_type?: AdjacencyTypeEnum | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<AdjacencyRiskResponseApi[], HTTPValidationError>({
        path: `/adjacency-risk/site/${siteId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags adjacency-risk
     * @name DeleteAdjacencyRisk
     * @summary Delete Adjacency Risk
     * @request DELETE:/adjacency-risk/site/{site_id}/adjacent_site/{adjacent_site_id}
     * @secure
     */
    deleteAdjacencyRisk: (
      adjacentSiteId: string,
      siteId: string,
      params: RequestParams = {}
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/adjacency-risk/site/${siteId}/adjacent_site/${adjacentSiteId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  parameters = {
    /**
     * @description Retrieve a specific parameter within a specified scope. This endpoint fetches a specific parameter identified by the provided parameter_uuid. You can optionally specify the major and minor version numbers to retrieve a specific version of the parameter. Please note that if a minor version is specified, a major version must also be provided.
     *
     * @tags parameters
     * @name GetParameterValue
     * @summary Get Parameter Value
     * @request GET:/parameters/{parameter_uuid}
     * @secure
     */
    getParameterValue: (
      parameterUuid: string,
      query?: {
        /**
         * Major Version
         * @default 0
         */
        major_version?: number | null;
        /**
         * Minor Version
         * @default 0
         */
        minor_version?: number | null;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      params: RequestParams = {}
    ) =>
      this.request<ParameterValueResponse, HTTPValidationError>({
        path: `/parameters/${parameterUuid}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a parameter for a specific parameter-id within a specified scope. If the scope is GLOBAL, the parameter will be available globally. If the scope is ORGANIZATION, the parameter will be available only within the organization. Only admin users can operate on the GLOBAL scope. This endpoint allows for the creation of a new parameter. The parameter data is provided in the request body. Steps: 1. Retrieve the Library for the specified parameter-id. If it doesn't exist, a new one is created. 2. Generate a new parameter using the provided data. If the parameter already exists, its minor version is incremented. If it's a new parameter, its minor version is set to 1. 3. Update the Library with the timestamp of the update and the identity of the user who performed the update. 4. Return the newly created parameter.
     *
     * @tags parameters
     * @name CreateParameterValue
     * @summary Create Parameter Value
     * @request PATCH:/parameters/
     * @secure
     */
    createParameterValue: (
      data: CreateParameterValueRequest,
      query?: {
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      params: RequestParams = {}
    ) =>
      this.request<ParameterValueResponse, HTTPValidationError>({
        path: `/parameters/`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Create parameters in batch for a specific parameter-id within a specified scope. If the scope is GLOBAL, the parameters will be available globally. If the scope is ORGANIZATION, the parameters will be available only within the organization. Only admin users can operate on the GLOBAL scope. This endpoint allows for the creation of multiple parameters at once. The parameters to be created are provided in a file, which is parsed and processed. Steps: 1. Parse the provided file to extract the parameters data. 2. Create a new Library associated with the specified parameter-id, incrementing the major version number. 3. Create a new resource for the Library. 4. Create a new job for the batch creation of parameters and run it. 5. Return the information about the job that was created for the batch creation of parameters. NOTE: Currently, if the batch creation fails, there is no automated rollback system. The rollback needs to be performed manually.
     *
     * @tags parameters
     * @name CreateParametersInBatch
     * @summary Create Parameters In Batch
     * @request POST:/parameters/batch
     * @secure
     */
    createParametersInBatch: (
      query: {
        parameter_id: ParameterId;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
      },
      data: BodyCreateParametersInBatchParametersBatchPost,
      params: RequestParams = {}
    ) =>
      this.request<SimpleJobResponse, HTTPValidationError>({
        path: `/parameters/batch`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Search for parameters based on various criteria. This endpoint allows for the searching of parameters based on parameter_id, major version, and attributes. The search results can be paginated using the cursor and limit parameters.
     *
     * @tags parameters
     * @name SearchParameters
     * @summary Search Parameters
     * @request POST:/parameters/search
     * @secure
     */
    searchParameters: (
      query: {
        parameter_id: ParameterId;
        /**
         * Major Version
         * @default 0
         */
        major_version?: number;
        /**
         * Scope
         * @default "GLOBAL"
         */
        scope?: Scope;
        /** Cursor */
        cursor?: string | null;
        /** Limit */
        limit?: number | null;
      },
      data: ParameterAttributes | null,
      params: RequestParams = {}
    ) =>
      this.request<
        PaginationResponseParameterValueResponse,
        HTTPValidationError
      >({
        path: `/parameters/search`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
